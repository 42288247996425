import { format } from 'date-fns';

import { TODAY } from '../../../utilities';
import { generateCottonQualityDataSummary, cottonQualityDailyOptions, cottonQualityExtremeExceedanceOptions, cottonQualityAvgTempOptions } from './cotton-quality';
import { caseStudy1TimeseriesOptions, caseStudy1AnnualOptions } from './case-study-1';
import { extremeHeatTimeseriesOptions, extremeHeatAnnualOptions } from './charts';
import { TAB_NAME, updateOverlay, updateChartData, updateTitle } from './fns';

const info = {
  name: TAB_NAME,
  subTabs: {
    'no-subheader-extreme-heat-group': [{
        name: 'Max > 90°F'
      },{
        name: 'Min > 70°F'
    }],
    'Case Studies': [{
      name: 'Case Study 1'
    },{
      name: 'Cotton Quality'
    }]
  },
  chartInfo: {
    chartSelectorId: 'extremeHeatChartSelector',
    charts: [{
      name: 'Hot Temperature Exceedance',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F'],
      func: (chartData, address, tabInfoContextState, selectedSubTab) => {
        const thresholdValue = tabInfoContextState.tabsSharedState.extremeHeatThresholdSelector.value;
        let threshold = typeof thresholdValue === 'function' ? thresholdValue({ selectedSubTab })[0] : thresholdValue[0];
        return extremeHeatTimeseriesOptions(chartData, address, threshold, 'above', selectedSubTab.slice(0,3));
      }
    },{
      name: 'Occurrences in Season',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F'],
      func: (chartData, address, tabInfoContextState, ...rest) => extremeHeatAnnualOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'CS1 Timeseries',
      showIfSubTab: ['Case Study 1'],
      func: (chartData, address, tabInfoContextState, ...rest) => caseStudy1TimeseriesOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'CS1 Annual',
      showIfSubTab: ['Case Study 1'],
      func: (chartData, address, tabInfoContextState, ...rest) => caseStudy1AnnualOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'Daily Average Temperatures',
      showIfSubTab: ['Cotton Quality'],
      func: (chartData, address, tabInfoContextState, ...rest) => cottonQualityDailyOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'Occurrences Exceeding Extreme Temperature Threshold',
      showIfSubTab: ['Cotton Quality'],
      func: (chartData, address, tabInfoContextState, ...rest) => cottonQualityExtremeExceedanceOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'Average Temperature from First Flower to First Boll',
      showIfSubTab: ['Cotton Quality'],
      func: (chartData, address, tabInfoContextState, ...rest) => cottonQualityAvgTempOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: updateOverlay,
    showLoading: true
  },{
    whenXChanges: ['extremeHeatThresholdSelector', 'extremeHeatSeasonBoundsSelector', 'cotton-quality-season-bounds-selector', 'case-study-1-threshold-selector', 'cotton-quality-optimal-range-selector', 'cotton-quality-extreme-temperature-selector', 'cotton-quality-gdd-range-selector', 'rawChartData', 'selectedSubTab'],
    handleChange: updateChartData,
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'extreme-heat-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'extreme-heat-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'extreme-heat-charts',
      useSharedState: 'extremeHeatChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'extreme-heat-threshold-selector',
      useSharedState: 'extremeHeatThresholdSelector',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F'],
      props: {
        columnHeading: 'Days Over',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        step: 1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'thresholdRangeSelector',
      id: 'case-study-1-threshold-selector',
      showIfSubTab: ['Case Study 1'],
      props: {
        unit: '°F',
        minValue: -200,
        maxValue: 200,
        value: [65, 90],
        showReset: true,
        automaticUpdateOnEdit: false
      }
    },{
      component: 'thresholdSelector',
      id: 'cotton-quality-extreme-temperature-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        columnHeading: '°F',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        step: 1,
        colors: ['black'],
        label: () => 'Extreme Temperature Threshold',
        initValue: 90,
        resetValue: 90,
        automaticUpdateOnEdit: false,
        showReset: true
      }
    },{
      component: 'thresholdRangeSelector',
      id: 'cotton-quality-optimal-range-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        unit: '°F',
        minValue: -200,
        maxValue: 200,
        value: [75, 80],
        resetValues: [75, 80],
        showReset: true,
        automaticUpdateOnEdit: false,
        label: () => 'Optimal Temperature Range'
      }
    },{
      component: 'thresholdRangeSelector',
      id: 'cotton-quality-gdd-range-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        unit: 'Base 60 GDDs',
        minValue: 0,
        maxValue: 20000,
        value: [800, 1700],
        resetValues: [800, 1700],
        step: 50,
        showReset: true,
        automaticUpdateOnEdit: false,
        label: () => 'Optimal GDD Range',
        maxLabel: 'First Open Bowl',
        minLabel: 'First Flower',
        resetBtnUnit: 'GDD Range'
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'extreme-heat-season-bounds-selector',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F', 'Case Study 1'],
      useSharedState: 'extremeHeatSeasonBoundsSelector'
    },{
      component: 'seasonBoundsSelector',
      id: 'cotton-quality-season-bounds-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        value: [`${TODAY.getFullYear() - (TODAY.getMonth() < 3 ? 1 : 0)}-04-01`, `${TODAY.getFullYear() - (TODAY.getMonth() < 3 ? 1 : 0)}-10-31`]
      }
    }]
  },
  componentFns: {
    title: updateTitle,
    aboveChartTextBlock: ({ selectedSubTab, display }) => {
      return (display === 'chart' && selectedSubTab === 'Cotton Quality') ? 'cotton-quality_above-charts.md' : null;
    }
  },
  textPanelInfo: [{
    name: 'Data Summary',
    onlyShowIfChart: true,
    onlyShowIfSubTab: ['Cotton Quality'],
    generateMarkdown: generateCottonQualityDataSummary,
  },{
    name: 'Strategies to Optimize Cotton Quality',
    markdownFilename: 'cotton-quality_strategies.md'
  }]
};

export default info;
export const extremeHeatSharedStateDefinitions = {
  'extremeHeatChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'extremeHeatThresholdSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      const newValue = parseInt(newSubTab.slice(6,8));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.slice(6,8))])
  },
  'extremeHeatSeasonBoundsSelector': {
    persist: false,
    initValue: [`${TODAY.getFullYear()}-01-01`, format(TODAY, 'yyyy-MM-dd')]
  },
}