const COLORS = {
  lightRed: '#fc5353',
  darkRed: '#C42020',
  darkRedRgb: '212, 89, 89',
  green: '#16aa2e',
  greenRgb: '52, 150, 45',
  lightBlue: '#6da9f7',
  darkBlue: '#436d96',
  lightGray: '#adadad',
  darkGray: '#6c6c6c',
  thresholds: ['#bae4b3', '#74c476', '#238b45','#2c7745', '#275436']
};

export default COLORS;