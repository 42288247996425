import {
  parseISO,
  differenceInDays,
  differenceInYears,
  isLeapYear,
  isBefore,
  isAfter,
  format
} from 'date-fns';

export const calcSeasonDates = (dateOccurred, seasonStartDay) => {
  // If date occurred is on Feb. 29th, change to Mar. 1st to avoid error
  if (dateOccurred.slice(5) === '02-29') {
    dateOccurred = dateOccurred.slice(0,5) + '03-01';
  }

  // Handle adjusting for leap years
  let doObj = parseISO(dateOccurred);

  let additionalDays = 1;
  if (isLeapYear(doObj) && !isBefore(doObj, new Date(doObj.getFullYear(), 2, 1))) {
    additionalDays += 1;
  }

  // Coerce to predefined year in order to chart day of season instead of actual date
  const dayOfSeason = Date.parse(format(doObj, '2021-MM-dd')+'T00:00');
  const dosDate = new Date(dayOfSeason);
  const seasonStartYear = isBefore(dosDate, parseISO('2021-' + seasonStartDay)) ? '2020' : '2021';

  const daysIntoSeason = differenceInDays(
    dosDate,
    parseISO(seasonStartYear + seasonStartDay)
  ) + additionalDays;

  return { dayOfSeason, daysIntoSeason };
};

export function calcInitStartDate(MMDD, currentDate) {
  const cYear = currentDate.getFullYear();
  const sDate = parseISO(`${cYear}-${MMDD}`);
  const adjustment = isBefore(currentDate, sDate) ? 1 : 0;
  return `${cYear - adjustment}-${MMDD}`;
}

export function calcSeasonYear(currentIsoDate, seasonStartIsoDate, seasonEndIsoDate) {
  const cDate = parseISO(currentIsoDate);
  const cYear = cDate.getFullYear();
  
  // Temporarily defined season start and end to validate them
  //    - season start must be before (not equal to) end
  //    - the season must be shorter than one full year (eg. if start is 3/5/2024 then end can be 3/4/2025 but not 3/5/2025 or later )
  let sDate = parseISO(seasonStartIsoDate);
  let eDate = parseISO(seasonEndIsoDate);
  if (!isAfter(eDate, sDate) || differenceInYears(eDate, sDate) > 0) {
    throw new Error("Invalid season start and end dates");
  }

  // Redefine season start and end to use in logic, giving them the same year as current date for easy comparison
  sDate = parseISO(cYear + seasonStartIsoDate.slice(4,10));
  eDate = parseISO(cYear + seasonEndIsoDate.slice(4,10));

  const seasonCrossesNewYear = isBefore(eDate, sDate);

  // There are three cases where the season year needs to be adjusted from the current year:
  //    - Case 1: current date is outside of a season and prior to season start date for year (eg. current = 3/1/2024, start = 4/1, end = 5/1), subtract one year
  //    - Case 2: current date is equal to season start and season wraps new year (eg. current = 10/1/2024, start = 10/1, end = 4/1), add one year
  //    - Case 3: current date is after season start and season wraps new year (eg. current = 10/1/2024, start = 9/1, end = 4/1), add one year
  if (seasonCrossesNewYear && !isAfter(sDate, cDate)) {
    // Case 2 + 3, because !isAfter === (isBefore || isSameDay)
    return cYear + 1;
  } else if (!seasonCrossesNewYear && isBefore(cDate, sDate)) {
    // Case 1
    return cYear - 1;
  } else {
    return cYear;
  }
}

export function isInSeason(currentIsoDate, seasonStartIsoDate, seasonEndIsoDate) {
  const cDate = parseISO(currentIsoDate);
  const cYear = cDate.getFullYear();
  
  // Temporarily defined season start and end to validate them
  //    - season start must be before (not equal to) end
  //    - the season must be shorter than one full year (eg. if start is 3/5/2024 then end can be 3/4/2025 but not 3/5/2025 or later )
  let sDate = parseISO(seasonStartIsoDate);
  let eDate = parseISO(seasonEndIsoDate);
  if (!isAfter(eDate, sDate) || differenceInYears(eDate, sDate) > 0) {
    throw new Error("Invalid season start and end dates");
  }

  // Redefine season start and end to use in logic, giving them the same year as current date for easy comparison
  sDate = parseISO(cYear + seasonStartIsoDate.slice(4,10));
  eDate = parseISO(cYear + seasonEndIsoDate.slice(4,10));

  // When all dates are in the same year:
  //   if season start is before season end and current is outside of those, return false because out of season
  //   if season end is before season start and current is between them, return false because out of season
  if (
    (isBefore(sDate, eDate) && (isBefore(cDate, sDate) || isAfter(cDate, eDate))) ||
    (isBefore(eDate, sDate) && isBefore(cDate, sDate) && isAfter(cDate, eDate))
  ) {
    return false;
  }

  return true;
}

