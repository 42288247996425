import { format } from 'date-fns';

import { TODAY, calcInitStartDate } from '../../../utilities';

import { TAB_NAME, addName, updateChartData, updateOverlay, updateTitle } from './fns';
import { accumulationThroughoutSeasonOptions, gddsAchievedOptions, thresholdExceedanceOptions } from './charts';
import { cornRootWormEmergenceOptions, cornRootWormAnnualEmergenceOptions } from './corn-root-worm';
import { europeanCornBorerGenerationsOptions, europeanCornBorerGenerationsAchievedOptions } from './european-corn-borer';

const info = {
  name: TAB_NAME,
  subTabs: {
    'no-subheader-gdds-group': [{ 
      name: 'Base 50',
    },{ 
      name: 'Base 32',
    }],
    'Case Studies': [{
      name: 'Corn Root Worm'
    },{
      name: 'European Corn Borer'
    }]
  },
  chartInfo: {
    chartSelectorId: 'gddChartSelector',
    charts: [{
      name: 'GDDs Accumulation Throughout Season',
      showIfSubTab: ['Base 50', 'Base 32'],
      func: (chartData, address, tabInfoContextState, ...rest) => addName(chartData, address, tabInfoContextState, accumulationThroughoutSeasonOptions)
    },{
      name: 'Seasonal GDDs Achieved',
      showIfSubTab: ['Base 50', 'Base 32'],
      func: (chartData, address, tabInfoContextState, ...rest) => addName(chartData, address, tabInfoContextState, gddsAchievedOptions)
    },{
      name: 'European Corn Borer Generations',
      showIfSubTab: ['European Corn Borer'],
      func: (chartData, address, ...rest) => europeanCornBorerGenerationsOptions(chartData, address)
    },{
      name: 'Seasonal Date of Generations',
      showIfSubTab: ['European Corn Borer'],
      func: (chartData, address, tabInfoContextState, ...rest) => europeanCornBorerGenerationsAchievedOptions(chartData, address, tabInfoContextState)
    },{
      name: 'Seasonal Date of Threshold Exceedance',
      showIfSubTab: ['Base 50', 'Base 32'],
      func: (chartData, address, tabInfoContextState, ...rest) => addName(chartData, address, tabInfoContextState, thresholdExceedanceOptions)
    },{
      name: 'Corn Root Worm Emergence',
      showIfSubTab: ['Corn Root Worm'],
      func: (chartData, address, ...rest) => cornRootWormEmergenceOptions(chartData, address)
    },{
      name: 'Annual Date of 50% Emergence',
      showIfSubTab: ['Corn Root Worm'],
      func: (chartData, address, ...rest) => cornRootWormAnnualEmergenceOptions(chartData, address)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: updateOverlay,
    showLoading: true
  },{
    whenXChanges: ['gddThresholdSelector', 'gddSeasonBoundsSelector', 'gddBaseSelector', 'rawChartData', 'selectedSubTab', 'corn-root-worm-season-bounds-selector', 'corn-root-worm-egg-hatch-selector', 'european-corn-borer-season-bounds-selector', 'european-corn-borer-generation-selector'],
    handleChange: updateChartData,
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'gdds-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'gdds-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'gdds-charts',
      useSharedState: 'gddChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'gdd-thresholds-selector',
      showIfSubTab: ['Base 50', 'Base 32'],
      useSharedState: 'gddThresholdSelector',
      props: {
        columnHeading: 'GDD',
        minNum: 0,
        maxNum: 5,
        minValue: 0,
        maxValue: Infinity,
        step: 100,
        initValue: 1000,
        colors: ['#bae4b3', '#74c476', '#238b45','#2c7745', '#275436']
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'gdd-season-bounds-selector',
      showIfSubTab: ['Base 50', 'Base 32'],
      useSharedState: 'gddSeasonBoundsSelector'
    },{
      component: 'thresholdSelector',
      id: 'gdd-base-selector',
      showIfSubTab: ['Base 50', 'Base 32'],
      useSharedState: 'gddBaseSelector',
      props: {
        columnHeading: 'GDD',
        unit: 'Base',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        initValue: 'gddSelectedSubTab',
        colors: ['black'],
        label: (value, unit) => `GDD ${unit} ${value}`,
        automaticUpdateOnEdit: false,
        showReset: true
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'corn-root-worm-season-bounds-selector',
      showIfSubTab: ['Corn Root Worm'],
      props: {
        value: [`${TODAY.getFullYear()}-01-01`, `${TODAY.getFullYear()}-12-31`]
      }
    },{
      component: 'dateSelector',
      id: 'corn-root-worm-egg-hatch-selector',
      showIfSubTab: ['Corn Root Worm'],
      props: {
        value: '',
        label: 'Biofix Date',
        minDate: (today) => new Date(today.getFullYear(),0,1),
        maxDate: (today) => today,
        today: new Date()
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'european-corn-borer-season-bounds-selector',
      showIfSubTab: ['European Corn Borer'],
      props: {
        value: [`${TODAY.getFullYear()}-01-01`, `${TODAY.getFullYear()}-12-31`]
      }
    },{
      component: 'thresholdSelector',
      id: 'european-corn-borer-generation-selector',
      showIfSubTab: ['European Corn Borer'],
      props: {
        columnHeading: 'Base 50',
        unit: 'GDDs',
        minNum: 1,
        maxNum: 1,
        minValue: 0,
        maxValue: 10000,
        initValue: 1192,
        resetValue: 1192,
        colors: ['black'],
        label: () => 'Generational Gap',
        showReset: true
      }
    }]
  },
  componentFns: {
    title: updateTitle
  },
  textPanelInfo: [{
    name: 'Analysis',
    onlyShowIfSubTab: ['Base 50', 'Base 32'],
    onlyShowIfChart: true,
    generateMarkdown: ({ chartData,  tabsSharedState }) => {
      try {
        const baseNumber = tabsSharedState.gddBaseSelector.value[0];
        let sdate = tabsSharedState.gddSeasonBoundsSelector.value;
        sdate = sdate.slice(5, 10) + '-' + sdate.slice(0,4);
        const sGdd = 0;
        let edate = chartData.timeseries.dates.slice(-1)[0] + '-' + sdate.slice(-4);
        const eGdd = chartData.timeseries.current[chartData.timeseries.dates.length - 1];
        
        return `#  Success \n The madlib text for GDD Base ${baseNumber} will go here.\n\n The current season started on ${sdate} at ${sGdd} Base ${baseNumber} GDDs and ended on ${edate} at ${eGdd} Base ${baseNumber} GDDs`;
      } catch {
        return '';
      }
    }
  },{
    name: 'Local Links',
    onlyShowIfSubTab: ['Base 50', 'Base 32'],
    onlyShowIfChart: true,
    generateMarkdown: ({ selectedLocation }) => {
      return `${selectedLocation.lng}, ${selectedLocation.lat} links: \n - [Duck Duck Go](https://duckduckgo.com) \n - [Google](https://google.com)`;
    }
  },{
    name: 'FAQ',
    onlyShowIfSubTab: ['Base 50', 'Base 32'],
    markdownFilename: 'faq_default.md'
  }]
};

export default info;
export const gddsSharedStateDefinitions = {
  'gddChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'gddThresholdSelector': {
    initValue: []
  },
  'gddSeasonBoundsSelector': {
    persist: false,
    initValue: [calcInitStartDate('03-01', TODAY), format(TODAY, 'yyyy-MM-dd')]
  },
  'gddBaseSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      let newValue = parseInt(newSubTab.slice(5));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.slice(5))])
  },
}