import { parseISO, format, isBefore } from 'date-fns';
import Spline from 'cubic-spline';

import {
  calcChartData,
  calcSeasonDates,
  calcSeasonYear,
  isInSeason,
  DynamicChillUnitsModel,
  updateOverlayFromR2,
  MODELS,
  TODAY
} from "../../../utilities";

export const TAB_NAME = 'Chill Accumulation';

export const addName = (chartData, address, fn) => {
  return fn(chartData, address, 'Chill Units');
};

// Creates spline function for temperature interpolation
const calcSpline = (ys) => {
  // Duplicate first and last day of temperature observations. This serves to mitigate the tails that the spline generates at the start and end
  ys.unshift(ys[0], ys[1]);
  ys.push(...ys.slice(-2));

  // Create xs array that represents the hour of each temperature observation
  const xs = Array.from({ length: ys.length }, (v, i) => 12 * i);
  
  // Create Spline from ACIS data
  return new Spline(xs, ys);
};

export const parseTemperaturesToSplines = (temperatures) => {
  const monthly = [];
  let currMonthTemperatures = {
    year: null,
    month: null,
    dates: [],
    temperatures: []
  };

  // Loop days adding to current month object until the first of next month is reached, then add the month data to array and reset for new month
  // mint and maxt are added to same array to be used as 'ys' in spline calculations
  for (let i = 0; i < temperatures.length; i++) {
    const [ dateStr, mint, maxt ] = temperatures[i];

    const year = dateStr.slice(0,4);
    const month = dateStr.slice(5,7);
    const day = dateStr.slice(8,10);

    if (day === '01') {
      if (currMonthTemperatures.year) {
        currMonthTemperatures.spline = calcSpline(currMonthTemperatures.temperatures);
        monthly.push({ ...currMonthTemperatures });
      }

      currMonthTemperatures = {
        year: parseInt(year),
        month: parseInt(month),
        dates: [],
        temperatures: []
      };
    }

    currMonthTemperatures.dates.push(dateStr);
    currMonthTemperatures.temperatures.push(mint);
    currMonthTemperatures.temperatures.push(maxt);
  }

  // Adds ending partial month
  currMonthTemperatures.spline = calcSpline(currMonthTemperatures.temperatures);
  monthly.push({ ...currMonthTemperatures });
  
  return monthly;
};

const calcChillChartData = (chillModel) => {
  return function(monthlyObjArray, thresholds, seasonBounds, TODAY) {
    const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];

    const sortedThresholds = thresholds.sort((a, b) => b - a);
    const newChartData = {
      years: [],
      maxs: {
        dateOccurred: [],
        values: [],
        dayOfSeason: [],
        daysIntoSeason: [],
      },
      onToday: {
        dateOccurred: seasonBounds[1],
        values: [],
        ...calcSeasonDates(today, seasonBounds[0].slice(5,10))
      },
      thresholds: Array.from({ length: sortedThresholds.length }, (v, i) => ({
        threshold: sortedThresholds[i],
        dateOccurred: [],
        dayOfSeason: [],
        daysIntoSeason: [],
      })),
    };

    const seasonStartDay = seasonBounds[0].slice(5,10);
    const seasonEndDay = seasonBounds[1].slice(5,10);

    let idxYearStart = null;
    let lastIdxProcessed = -1;
    let dateIdx = 0;

    const selectedSeason = {
      started: false,
      ended: false,
      current: [],
      dates: [],
      chillSum: 0,
      dynamicChillUnitsModel: new DynamicChillUnitsModel()
    };
    let dynamicChillUnitsModel, dailyChill, yearDates, chillSum, chillMax, thresholdsToPass;
    monthlyObjArray.forEach(({ dates, spline }) => {
      for (let i = 0; i < dates.length; i++) {
        const strDate = dates[i];
        const isToday = strDate.slice(5,10) === today.slice(5,10);

        if (strDate === seasonBounds[0]) selectedSeason.started = true;
        
        // If current day is the start of the season
        if (strDate.slice(5) === seasonStartDay) {
          // Move pointer
          idxYearStart = dateIdx;

          // Reset all variables used for tracking season
          thresholdsToPass = [...sortedThresholds];
          dailyChill = [];
          yearDates = [];
          chillMax = ['', 0];
          chillSum = 0;

          // Create a new Dynamic model for each season (only gets used if Dynamic model is selected)
          dynamicChillUnitsModel = new DynamicChillUnitsModel();
        }

        if (selectedSeason.started && !selectedSeason.ended) {
          // Start hour is adjusted by one day to account for the duplicated first day (see calcSpline function)
          const startHour = (i + 1) * 24;
          const endHour = startHour + 24;
          
          for (let j = startHour; j < endHour; j++) {
            // Use Spline to calculate the hourly temp
            const hourlyTemp = spline.at(j);
      
            // Use hourly temp to calculate hourly chill units
            const chill = chillModel(hourlyTemp, selectedSeason.dynamicChillUnitsModel);
            // Add chill units to sum, but ensure that the sum never goes below 0
            selectedSeason.chillSum = Math.max(0, selectedSeason.chillSum + chill);
          }

          // Add day to tracking arrays
          selectedSeason.current.push(selectedSeason.chillSum);
          selectedSeason.dates.push(strDate);
        }

        if (idxYearStart !== null && idxYearStart > lastIdxProcessed) {
          // Start hour is adjusted by one day to account for the duplicated first day (see calcSpline function)
          const startHour = (i + 1) * 24;
          const endHour = startHour + 24;
          
          for (let j = startHour; j < endHour; j++) {
            // Use Spline to calculate the hourly temp
            const hourlyTemp = spline.at(j);
      
            // Use hourly temp to calculate hourly chill units
            const chill = chillModel(hourlyTemp, dynamicChillUnitsModel);
            // Add chill units to sum, but ensure that the sum never goes below 0
            chillSum = Math.max(0, chillSum + chill);
      
            // Check if new sum is new max
            if (chillSum > chillMax[1]) {
              chillMax = [strDate, chillSum];
            }
            
            // Check if chill unit sum has crossed any of the thresholds provided in thresholdArr
            for (let k = 0; k < thresholdsToPass.length; k++) {
              const threshold = thresholdsToPass[k];
              
              // Store the date that the threshold was crossed
              if (chillSum >= threshold) {
                thresholdsToPass[k] = Infinity;
                const { dayOfSeason, daysIntoSeason } = calcSeasonDates(strDate, seasonBounds[0].slice(5,10));
                newChartData.thresholds[k].dateOccurred.push(strDate);
                newChartData.thresholds[k].dayOfSeason.push(dayOfSeason);
                newChartData.thresholds[k].daysIntoSeason.push(daysIntoSeason);
              }
            }
          }

          // Add day to tracking arrays
          dailyChill.push(chillSum);
          yearDates.push(strDate);

          // If it is today, then set onToday value
          if (isToday) {
            newChartData.onToday.values.push(chillSum);
          }

          // If it is the end of the season (and there was a season being tracked)
          if (strDate.slice(5) === seasonEndDay) {
            // Add season year to years array. Season year could be different from strDate's year
            const seasonYear = calcSeasonYear(strDate, seasonBounds[0], seasonBounds[1]);
            newChartData.years.push(seasonYear);

            if (chillMax[0] === '') {
              chillMax[0] = strDate;
            }

            // Add season data to relevant arrays
            const { dayOfSeason, daysIntoSeason } = calcSeasonDates(chillMax[0], seasonStartDay);
            newChartData.maxs.values.push(chillMax[1]);
            newChartData.maxs.dateOccurred.push(chillMax[0]);
            newChartData.maxs.dayOfSeason.push(dayOfSeason);
            newChartData.maxs.daysIntoSeason.push(daysIntoSeason);
            
            // Update to the index of the current date, as it was the last to be used
            lastIdxProcessed = dateIdx;
          }
        }

        if (strDate === seasonBounds[1]) selectedSeason.ended = true;

        dateIdx++;
      }
    });
      
    if (idxYearStart !== null && idxYearStart > lastIdxProcessed) {
      // Data ended in middle of season, treat it like the end of the season
      // Add season year to years array. Season year could be different from strDate's year
      const seasonYear = calcSeasonYear(yearDates.slice(-1)[0], seasonBounds[0], seasonBounds[1]);
      newChartData.years.push(seasonYear);

      if (chillMax[0] === '') {
        chillMax[0] = yearDates.slice(-1)[0];
      }

      // Add season data to relevant arrays
      const { dayOfSeason, daysIntoSeason } = calcSeasonDates(chillMax[0], seasonStartDay);
      newChartData.maxs.values.push(chillMax[1]);
      newChartData.maxs.dateOccurred.push(chillMax[0]);
      newChartData.maxs.dayOfSeason.push(dayOfSeason);
      newChartData.maxs.daysIntoSeason.push(daysIntoSeason);
    }

    newChartData.timeseries = { current: selectedSeason.current, dates: selectedSeason.dates };
    return newChartData;
  }
};

export function updateChillChartData({ selectedTab, selectedSubTab, selectedLocation, tabsSharedState, rawChartData }, _, { setRawChartData, setChartData }) {
  if (selectedTab === TAB_NAME && selectedLocation in rawChartData && 'mintMaxt' in rawChartData[selectedLocation]) {
    if (!(selectedTab in rawChartData[selectedLocation])) {
      const newRawChartData = { ...rawChartData };
      const monthlyObjArray = parseTemperaturesToSplines(newRawChartData[selectedLocation]['mintMaxt']);
      newRawChartData[selectedLocation][selectedTab] = monthlyObjArray;
      return () => setRawChartData(newRawChartData);
    } else {
      const newChartData = calcChartData(
        rawChartData[selectedLocation][selectedTab],
        tabsSharedState.chillUnitsThresholdSelector.value,
        calcChillChartData(MODELS[selectedSubTab]),
        tabsSharedState.chillUnitsSeasonBoundsSelector.value,
        TODAY      
      )
      return () => setChartData(newChartData);
    }
  }
  return () => {};
}

export function updateOverlay({ selectedSubTab }, _, { updateOverlayData }) {
  const jsonFileName = `${selectedSubTab.split(' ').join('_').toLowerCase()}.json`;
  const jsonFileDataKey = 'departures';
  return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
    .then(d => () => updateOverlayData(d));
}

export function updateTitle({display, selectedSubTab, overlayDataDate, chartData}) {
  try {
    let sdate, edate;
    if (display === 'map') {
      const dateObj = parseISO(overlayDataDate);
      const isCurrentSeasonYear = isBefore(dateObj, new Date(dateObj.getFullYear(), 8, 1));
      const syear = dateObj.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
      sdate = format(new Date(syear, 8, 1), 'LLL do, yyyy');
      edate = format(dateObj, 'LLL do, yyyy');
    } else {
      sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
      edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
    }
    
    return {
      type: `${selectedSubTab} Chill Units`,
      sdate,
      edate
    }
  } catch {
    return null;
  }
}