import { COLORS } from "../../../utilities";

export function cattleTickTimeseriesOptions(chartData, address) {
  const min = Math.min(...chartData.timeseries.current, chartData.thresholds[0] - 5);
  const max = Math.max(...chartData.timeseries.current, chartData.thresholds[1] + 5);

  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: 'Cattle Tick Timeseries',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.timeseries.current,
        name: 'Current Season',
        zones: [{
          value: chartData.thresholds[0],
          color: COLORS.lightBlue
        },{
          value: chartData.thresholds[1],
          color: `rgb(100, 100, 100)`
        },{
          color: COLORS.darkRed
        }]
      }
    ],
    xAxis: {
      categories: chartData.timeseries.dates,
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        title: {
          text: 'Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands: [{
          from: chartData.thresholds[0],
          to: chartData.thresholds[1]
        }]
      },
    ],
    legend: {
      enabled: false,
    },
  };
}

export const calcCattleTickChartData = (temps, thresholds, TODAY) => {
  // Count of days with max temp in threshold range
  function isInRange(val) {
    return thresholds[0] <= val && val <= thresholds[1];
  }

  const newChartData = {
    thresholds,
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      toDateDate: null,
      finalDataDate: null,
      fullSeason: []
    }
  };
  
  const today = TODAY.toISOString().slice(0,10);
  const seasonStartDay = '01-01';
  const seasonEndDay = today.slice(5,10);
  newChartData.yearlySeasons.toDateDate = today;
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (isToday && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.toDate.push(seasonData.filter((dayData) => isInRange(dayData[2])).length);

      newChartData.timeseries.dates.push(parseInt(strDate.slice(0,4)));
      newChartData.timeseries.current.push(seasonData.reduce((acc, dayData, i) => {
        acc.sum += dayData[1];
        acc.sum += dayData[2];
        acc.count += 2;

        if (i === seasonData.length - 1) {
          return Math.round(acc.sum / acc.count * 100) / 100;
        } else {
          return acc;
        }
      }, { sum: 0, count: 0 }));
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.years.push(parseInt(strDate.slice(0,4)));
      
      // Count of days with max temp in threshold range
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter((dayData) => isInRange(dayData[2])).length);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = temps.slice(idxYearStart);
    const seasonYear = currentSeasonData[currentSeasonData.length - 1][0].slice(0,4);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter((dayData) => isInRange(dayData[2])).length);
    newChartData.yearlySeasons.fullSeason.push(null);
    newChartData.yearlySeasons.finalDataDate = currentSeasonData.slice(-1)[0][0];
    newChartData.timeseries.dates.push(seasonYear);
    newChartData.timeseries.current.push(currentSeasonData.reduce((acc, dayData, i) => {
      acc.sum += dayData[1];
      acc.sum += dayData[2];
      acc.count += 2;

      if (i === currentSeasonData.length - 1) {
        return Math.round(acc.sum / acc.count * 100) / 100;
      } else {
        return acc;
      }
    }, { sum: 0, count: 0 }));
  } else {
    newChartData.yearlySeasons.finalDataDate = temps[lastIdxProcessed][0];
  }

  return newChartData;
};