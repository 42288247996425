import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../../components/tooltip-season/tooltip-season.component';

import { COLORS, calcSeasonYear, isInSeason, isPastThreshold, averageArray, countAbove, calculateGddAccumulations } from '../../../utilities';

export function cottonQualityDailyOptions(chartData, address) {
  const extremeThreshold = chartData.extremeThreshold[0] === undefined ? 90 : chartData.extremeThreshold[0];
  const min = Math.min(...chartData.timeseries.current, extremeThreshold - 5);
  const max = Math.max(...chartData.timeseries.current, extremeThreshold + 5);

  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: 'Daily Average Temperatures',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.timeseries.current,
        name: 'Current Season',
        zones: [{
          value: extremeThreshold,
          color: COLORS.lightGray
        },{
          color: `rgb(${COLORS.darkRedRgb})`
        }]
      }
    ],
    xAxis: {
      categories: chartData.timeseries.dates.map(d => d.slice(5,10).replace('-', '/')),
      title: {
        text: 'Day of Year',
      },
      plotLines: [{
        color: 'rgb(150,150,150)',
        value: chartData.firstFlowerIdx,
        label: {
          text: 'First Flower',
          style: {
            color: 'rgb(80,80,80)',
          },
        },
        dashStyle: 'dash'
      },{
        color: 'rgb(150,150,150)',
        value: chartData.firstOpenBollIdx,
        label: {
          text: 'First Open Bowl',
          style: {
            color: 'rgb(80,80,80)',
          },
        },
        dashStyle: 'dash'
      }],
    },
    yAxis: [
      {
        min,
        max,
        title: {
          text: 'Avg. Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands: [{
          from: extremeThreshold,
          to: Infinity,
          color: `rgba(${COLORS.darkRedRgb}, 0.3)`
        }]
      },
    ],
    legend: {
      enabled: false,
    },
  };
}

export function cottonQualityExtremeExceedanceOptions(chartData, address, seasonBounds) {
  const { years, toDate, toDateDate, fullSeason } = chartData.extremeYearly;
  const min = 0;
  const max = Math.max(...fullSeason, ...toDate);

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  const finalCurrentYearDate = chartData.timeseries.dates.slice(-1)[0];
  let finalDataDate = new Date(finalCurrentYearDate) < new Date(toDateDate) ? finalCurrentYearDate : toDateDate;
  finalDataDate = finalDataDate.slice(5,10).replace('-','/');

  const toDateNormal = Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1));

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: `Occurrences Exceeding Extreme Temperature Threshold for the Season: ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'At End of Season',
        color: COLORS.lightGray,
        opacity: 0.6,
        borderColor: COLORS.lightGray,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: `rgb(${COLORS.darkRedRgb})`,
        pointPadding: 0.125,
        zones: [{
          value: toDateNormal,
          color: COLORS.lightGray
        },{
          color: `rgb(${COLORS.darkRedRgb})`
        }]
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: toDateNormal,
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        const isCurrentYear = date === years.slice(-1)[0];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.lightBlue} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name === 'To Date' ? `To ${isCurrentYear ? finalDataDate : toDateDate.slice(5,10).replace('-','/')}` : point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

export function cottonQualityAvgTempOptions(chartData, address) {
  const { years, fullSeason } = chartData.rangeYearly;
  const min = Math.min(...fullSeason, chartData.optimalThresholds[0] - 5);
  const max = Math.max(...fullSeason, chartData.optimalThresholds[1] + 5);

  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: 'Average Temperature from First Flower to First Boll',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'Average Temperature',
        zones: [{
          value: chartData.optimalThresholds[0],
          color: COLORS.lightBlue
        },{
          value: chartData.optimalThresholds[1],
          color: `rgb(${COLORS.greenRgb})`
        },{
          color: `rgb(${COLORS.darkRedRgb})`
        }]
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Avg. Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands: [{
          from: chartData.optimalThresholds[0],
          to: chartData.optimalThresholds[1],
          color: `rgba(${COLORS.greenRgb}, 0.1)`
        }]
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.lightBlue} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'center' }}>
                <div className='mc-number'>{point.y}</div>
                <div className='mc-units'>°F</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

function average(v1, v2) {
  return Math.round((v1 + v2) / 2 * 100) / 100;
}

function findRangeIndices(gdds, thresholds) {
  const firstFlowerIdx = gdds.findIndex(gdd => gdd >= thresholds[0]);
  const firstOpenBollIdx = gdds.findIndex(gdd => gdd >= thresholds[1]);
  return { firstFlowerIdx, firstOpenBollIdx };
}

function calculateAverageTemp(temps) {
  let sum = 0;
  let count = 0;
  temps.forEach(([date, mint, maxt]) => {
    sum += mint;
    sum += maxt;
    count += 2;
  });
  return Math.round(sum / count * 100) / 100;
}

function calculateAverageTempWithinGddRange(seasonTemps, seasonGdds, gddThresholds) {
  const accumulations = calculateGddAccumulations(seasonGdds);
  const rangeIndices = findRangeIndices(accumulations, gddThresholds);
  
  let avgTemp = null;
  if (rangeIndices.firstFlowerIdx !== -1) {
    avgTemp = calculateAverageTemp(seasonTemps.slice(rangeIndices.firstFlowerIdx, rangeIndices.firstOpenBollIdx === -1 ? seasonTemps.length : rangeIndices.firstOpenBollIdx + 1));
  }

  return { avgTemp, ...rangeIndices };
}

export function calcCottonQualityChartData(temps, gdds, gddThresholds, optimalThresholds, extremeThreshold, seasonBounds, TODAY) {
  const compFn = isPastThreshold(extremeThreshold, 'above');
  const dataIdx = 2;    // maxt

  const newChartData = {
    seasonBounds,
    gddThresholds,
    optimalThresholds,
    extremeThreshold,
    firstFlowerIdx: null,
    firstOpenBollIdx: null,
    timeseries: {
      dates: [],
      current: [],
    },
    rangeYearly: {
      years: [],
      fullSeason: []
    },
    extremeYearly: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.extremeYearly.toDateDate = today.slice(0,10);
  newChartData.rangeYearly.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;

    if (isToday && idxYearStart !== null) {
      // To date
      const seasonTempData = temps.slice(idxYearStart, i + 1);
      newChartData.extremeYearly.toDate.push(seasonTempData.filter((dayData) => compFn(dayData[dataIdx])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      // End of season
      const seasonTempData = temps.slice(idxYearStart, i + 1);
      newChartData.extremeYearly.years.push(parseInt(strDate.slice(0,4)));
      newChartData.extremeYearly.fullSeason.push(seasonTempData.filter((dayData) => compFn(dayData[dataIdx])).length);
      const seasonGddData = gdds.slice(idxYearStart, i + 1);
      const { avgTemp, firstFlowerIdx, firstOpenBollIdx } = calculateAverageTempWithinGddRange(seasonTempData, seasonGddData, gddThresholds);
      newChartData.rangeYearly.years.push(parseInt(strDate.slice(0,4)));
      newChartData.rangeYearly.fullSeason.push(avgTemp);
      newChartData.firstFlowerIdx = firstFlowerIdx;
      newChartData.firstOpenBollIdx = firstOpenBollIdx;
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      // Start of season
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    // Finish partial season for yearly and to use as timeseries
    const currentSeasonTempData = temps.slice(idxYearStart);
    const currentSeasonGddData = gdds.slice(idxYearStart);
    const seasonYear = calcSeasonYear(currentSeasonTempData[currentSeasonTempData.length - 1][0], seasonBounds[0], seasonBounds[1]);
    newChartData.extremeYearly.years.push(seasonYear);
    newChartData.extremeYearly.toDate.push(currentSeasonTempData.filter((dayData) => compFn(dayData[dataIdx])).length);
    newChartData.extremeYearly.fullSeason.push(null);
    newChartData.rangeYearly.years.push(seasonYear);
    const { avgTemp } = calculateAverageTempWithinGddRange(currentSeasonTempData, currentSeasonGddData, gddThresholds);
    newChartData.rangeYearly.fullSeason.push(avgTemp);
  }
  
  const selectedSeasonTempData = temps.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? temps.length : selectedSeasonIndices[1] + 1);
  const selectedSeasonGddData = gdds.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? gdds.length : selectedSeasonIndices[1] + 1);
  selectedSeasonTempData.forEach(dayData => {
    newChartData.timeseries.dates.push(dayData[0]);
    newChartData.timeseries.current.push(average(dayData[1], dayData[2]));
  });
  const { firstFlowerIdx, firstOpenBollIdx } = calculateAverageTempWithinGddRange(selectedSeasonTempData, selectedSeasonGddData, gddThresholds);
  newChartData.firstFlowerIdx = firstFlowerIdx;
  newChartData.firstOpenBollIdx = firstOpenBollIdx;
  
  return newChartData;
}

export function generateCottonQualityDataSummary({ chartData }) {
  try {
    const toDateNormal = Math.round(chartData.extremeYearly.toDate.slice(0, -1).reduce((a,b) => a + b) / (chartData.extremeYearly.toDate.length - 1));
    const extremeThreshold = chartData.extremeThreshold[0] + '°F';

    const idxOf2000 = chartData.extremeYearly.years.findIndex(y => y === 2000);
    const arr1951To1999 = chartData.extremeYearly.fullSeason.slice(0, idxOf2000);
    const arr2000ToPresent = chartData.extremeYearly.fullSeason.slice(idxOf2000);

    const tempAvg1951To1999 = averageArray(arr1951To1999);
    const tempAvg2000ToPresent = averageArray(arr2000ToPresent);
    const tempStayedSame = tempAvg1951To1999 === tempAvg2000ToPresent ? 'remained constant' : null;
    const tempDiff = Math.round((tempAvg1951To1999 - tempAvg2000ToPresent) * 100) / 100;
    const tempIncOrDec = (tempDiff > 0 ? 'decreased' : 'increased') + ' by ' + Math.abs(tempDiff) + '%';

    const cntGtLowerOpt1951To1999 = countAbove(arr1951To1999, chartData.optimalThresholds[0]);
    const cntGtUpperOpt1951To1999 = countAbove(arr1951To1999, chartData.optimalThresholds[1]);
    const pctInOpt1951To1999 = Math.round((cntGtLowerOpt1951To1999 - cntGtUpperOpt1951To1999) / arr1951To1999.length * 100) / 100;

    const cntGtLowerOpt2000ToPresent = countAbove(arr2000ToPresent, chartData.optimalThresholds[0]);
    const cntGtUpperOpt2000ToPresent = countAbove(arr2000ToPresent, chartData.optimalThresholds[1]);
    const pctInOpt2000ToPresent = Math.round((cntGtLowerOpt2000ToPresent - cntGtUpperOpt2000ToPresent) / arr2000ToPresent.length * 100) / 100;

    const pctStayedSame = pctInOpt1951To1999 === pctInOpt2000ToPresent ? 'remained constant' : null;
    const pctDiff = Math.round((pctInOpt1951To1999 - pctInOpt2000ToPresent) * 100) / 100;
    const pctIncOrDec = pctDiff > 0 ? 'decreased' : 'increased';

    const currentDaysAboveExtreme = countAbove(chartData.timeseries.current, chartData.extremeThreshold[0]);
    
    
    const lastDataDate = chartData.timeseries.dates.slice(-1)[0];
    const currentYear = Math.min(parseInt(lastDataDate.slice(0,4)), parseInt(chartData.seasonBounds[1].slice(0,4)));

    const seasonEnded = lastDataDate.slice(5,10) === chartData.seasonBounds[1].slice(5,10);
    let ending = '';
    if (seasonEnded) {
      ending = `During the ${currentYear} season, ${currentDaysAboveExtreme} days exceeded ${extremeThreshold}. Temperatures averaged `
    } else {
      ending = `So far, during the ${currentYear} season, ${currentDaysAboveExtreme} days have exceeded ${extremeThreshold}. Temperatures are averaging `;
    }

    const currentAvgTemp = averageArray(chartData.timeseries.current);
    let currentTempTrend = 'within';
    if (currentAvgTemp < chartData.optimalThresholds[0]) {
      currentTempTrend = 'colder than';
    } else if (currentAvgTemp > chartData.optimalThresholds[1]) {
      currentTempTrend = 'warmer than';
    }
    ending += ` ${currentTempTrend} the optimal range.`;
    
    return `\nSince 1951 an average of ${toDateNormal} days above ${extremeThreshold} have occurred each year. Since 2000, the number of annual ${extremeThreshold} exceedances has ${tempStayedSame || tempIncOrDec} compared to the 1951-1999 period.\nFrom 1951-1999 ,${pctInOpt1951To1999}% of the growing seasons had temperatures within the optimal range, this percentage has ${pctStayedSame || pctIncOrDec} in more recent years. From 2000-present temperatures were optimal in ${pctInOpt2000ToPresent}% of the growing seasons.\n${ending}`;
  } catch {
    return '';
  }
}