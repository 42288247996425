import { format } from 'date-fns';

import { TODAY, calcInitStartDate } from '../../../utilities';
import { TAB_NAME, addName, updateOverlay, updateChillChartData, updateTitle } from './fns';
import { accumulationThroughoutSeasonOptions, chillUnitsAchievedOptions, maxChillUnitsOptions, thresholdExceedanceOptions } from './charts';

const info = {
  name: TAB_NAME,
  subTabs: [
    {
      name: 'North Carolina Model'
    },{
      name: 'Utah Model'
    },{
      name: 'Dynamic Model'
    },
  ],
  chartInfo: {
    chartSelectorId: 'chillUnitsChartSelector',
    charts: [{ 
      name: 'Chill Unit Accumulation Timeseries',
      func: (chartData, address, ...rest) => addName(chartData, address, accumulationThroughoutSeasonOptions)
    },{ 
      name: 'Chill Units Achieved On This Date',
      func: (chartData, address, ...rest) => addName(chartData, address, chillUnitsAchievedOptions)
    },{ 
      name: 'Date of Maximum Chill Units',
      func: (chartData, address, ...rest) => addName(chartData, address, maxChillUnitsOptions)
    },{ 
      name: 'Date of Threshold Exceedance',
      func: (chartData, address, ...rest) => addName(chartData, address, thresholdExceedanceOptions)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: updateOverlay,
    showLoading: true
  },{
    whenXChanges: ['chillUnitsThresholdSelector', 'chillUnitsSeasonBoundsSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: updateChillChartData,
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'chill-units-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'chill-units-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'chill-units-charts-selector',
      useSharedState: 'chillUnitsChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'chill-units-threshold-selector',
      useSharedState: 'chillUnitsThresholdSelector',
      props: {
        columnHeading: 'Chill Unit',
        minNum: 0,
        maxNum: 3,
        minValue: 0,
        maxValue: Infinity,
        step: 100,
        initValue: 1000,
        colors: ['#bae4b3', '#74c476', '#238b45']
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'chill-units-season-bounds-selector',
      useSharedState: 'chillUnitsSeasonBoundsSelector'
    }]
  },
  componentFns: {
    title: updateTitle
  },
  textPanelInfo: [{
    name: 'How To Use Tool',
    markdownFilename: 'how-to-use_chill.md'
  },{
    name: 'FAQ',
    markdownFilename: 'faq_default.md'
  },{
    name: 'About The Data',
    markdownFilename: 'about-the-data_chill.md'
  }]
};

export default info;
export const chillUnitsSharedStateDefinitions = {
  'chillUnitsChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'chillUnitsThresholdSelector': {
    initValue: []
  },
  'chillUnitsSeasonBoundsSelector': {
    persist: false,
    initValue: [calcInitStartDate('09-01', TODAY), format(TODAY, 'yyyy-MM-dd')]
    // initValue: () => {
    //   const isCurrentSeasonYear = isWithinInterval(TODAY, {
    //     start: new Date(TODAY.getFullYear(), 0, 1),
    //     end: new Date(TODAY.getFullYear(), 7, 31)
    //   });
    //   const startYear = TODAY.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
      
    //   return [`${startYear}-09-01`, format(TODAY, 'yyyy-MM-dd')];
    // }
  }
}