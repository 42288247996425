export function checkIfArraysContainSameValues(arrayOne, arrayTwo) {
  const arrayOneLength = arrayOne.length;
  const arrayTwoLength = arrayTwo.length;

  if (arrayOneLength !== arrayTwoLength) return false;     

  const arrayOneMatchesLength = arrayOne.filter( element => arrayTwo.includes(element)).length;
  const arrayTwoMatchesLength = arrayTwo.filter( element => arrayOne.includes(element)).length;

  return new Set([arrayOneLength, arrayTwoLength, arrayOneMatchesLength, arrayTwoMatchesLength ]).size === 1 ? true : false;
}

export function calculateGddAccumulations(gdds) {
  const accumulations = [];
  let sum = 0;
  gdds.forEach(([date, gdd]) => {
    sum += gdd;
    accumulations.push(sum);
  });
  return accumulations;
}