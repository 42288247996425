import { format, parseISO } from 'date-fns';

import {
  roundXDigits,
  calcSeasonYear,
  isInSeason,
  calcChartData,
  calcSeasonDates,
  updateOverlayFromR2,
  fetchFromAcis,
  TODAY
} from '../../../utilities';
import { calcCornRootWormChartData } from './corn-root-worm';

export const TAB_NAME = 'Degree Day Based Indicators';

export const addName = (chartData, address, tabInfoContextState, fn) => {
  const { tabsSharedState } = tabInfoContextState;
  const base = tabsSharedState.gddBaseSelector.value[0];
  return fn(chartData, address, `Base ${base} GDDs`);
};

const calcGddChartData = (gdds, thresholds, seasonBounds, TODAY) => {
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  
  const sortedThresholds = [...thresholds].sort((a, b) => a - b);
  const newChartData = {
    years: [],
    onToday: {
      dateOccurred: null,
      values: [],
      dayOfSeason: null,
      daysIntoSeason: null
    },
    thresholds: Array.from({ length: sortedThresholds.length }, (v, i) => ({
      threshold: sortedThresholds[i],
      dateOccurred: [],
      dayOfSeason: [],
      daysIntoSeason: [],
    })),
    timeseries: {
      dates: [],
      current: [],
      last: [],
      normal: []
    }
  };
  const normalSums = {};

  let inTimeseries = false;
  let ts = [...sortedThresholds];
  let currSum = 0;
  let idxYearStart = null;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < gdds.length; i++) {
    const [strDate, dayGddAmount] = gdds[i];

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;

    if (strDate.slice(5) === seasonStartDay) {
      currSum = 0;
      inTimeseries = true;
      ts = [...sortedThresholds];
      idxYearStart = i;
    }

    if (inTimeseries) {
      currSum += dayGddAmount;
      if (!(strDate.slice(5) in normalSums)) normalSums[strDate.slice(5)] = { sum: 0, count: 0 };
      normalSums[strDate.slice(5)].sum += currSum;
      normalSums[strDate.slice(5)].count += 1;
      
      if (ts.length && ts[0] <= currSum) {
        const { daysIntoSeason: currDaysIntoSeason, dayOfSeason: currDayOfSeason } = calcSeasonDates(strDate, seasonStartDay);
        const t = ts[0];
        const tObj = newChartData.thresholds.find(obj => obj.threshold === t);
        tObj.dateOccurred.push(strDate);
        tObj.dayOfSeason.push(currDayOfSeason);
        tObj.daysIntoSeason.push(currDaysIntoSeason);
        ts.shift();
      }

      if (strDate.slice(5,10) === today.slice(5,10)) {
        let { daysIntoSeason: d1, dayOfSeason: d2 } = calcSeasonDates(strDate, seasonStartDay);
        newChartData.onToday.daysIntoSeason = d1;
        newChartData.onToday.dayOfSeason = d2;
        newChartData.onToday.dateOccurred = strDate;
        newChartData.onToday.values.push(currSum);
      }
    }
    
    if ((strDate.slice(5) === seasonEndDay && idxYearStart !== null) || (inTimeseries && i === gdds.length - 1)) {
      const seasonYear = calcSeasonYear(strDate, seasonBounds[0], seasonBounds[1]);
      newChartData.years.push(seasonYear);
      
      ts.forEach(t => {
        const tObj = newChartData.thresholds.find(obj => obj.threshold === t);
        tObj.dateOccurred.push(null);
        tObj.dayOfSeason.push(null);
        tObj.daysIntoSeason.push(null);
      });
      
      inTimeseries = false;
    }
  }

  let currentSeasonData;
  if (selectedSeasonIndices[0] !== null && (selectedSeasonIndices[1] === null || selectedSeasonIndices[0] > selectedSeasonIndices[1])) {
    currentSeasonData = gdds.slice(selectedSeasonIndices[0]);
  } else if (selectedSeasonIndices[0] !== null && selectedSeasonIndices[1] !== null && selectedSeasonIndices[0] < selectedSeasonIndices[1]) {
    currentSeasonData = gdds.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] + 1);
  }
  
  let currSeasonSum = 0;
  currentSeasonData.forEach(([date, gdd]) => {
    const monthDay = date.slice(5);
    if (monthDay !== '02-29') {
      currSeasonSum += gdd;
      newChartData.timeseries.current.push(roundXDigits(currSeasonSum,0));
      newChartData.timeseries.dates.push(date);
    }
  });

  return newChartData;
};

export function updateChartData({ selectedTab, selectedSubTab, selectedLocation, tabInformation, tabsSharedState, rawChartData, pastLocations }, _, { setRawChartData, setChartData, updateCurrentTabInformation }) {
  if (selectedTab === TAB_NAME && selectedLocation in rawChartData) {
    let newChartData = {};
    if (selectedSubTab === 'Corn Root Worm') {
      const tabIdx = tabInformation.findIndex(({ name }) => name === TAB_NAME);
      const subTabChartOptions = tabInformation[tabIdx].pageInfo[selectedSubTab].chart.options;
      const seasonBounds = subTabChartOptions.find(({ id }) => id === 'corn-root-worm-season-bounds-selector').props.value;
      const eggHatchDateIdx = subTabChartOptions.findIndex(({ id }) => id === 'corn-root-worm-egg-hatch-selector');
      let eggHatchDate = subTabChartOptions[eggHatchDateIdx].props.value;
      newChartData = calcCornRootWormChartData(rawChartData[selectedLocation]['gddBase52'], rawChartData[selectedLocation]['mintMaxt'], eggHatchDate, seasonBounds);

      // Updates the selector values to match new chart values
      tabInformation[tabIdx].pageInfo[selectedSubTab].chart.options[eggHatchDateIdx].props.value = newChartData.eggHatchDate;
      tabInformation[tabIdx].pageInfo[selectedSubTab].chart.options[eggHatchDateIdx].props.minDate = () => parseISO(seasonBounds[0]);
      tabInformation[tabIdx].pageInfo[selectedSubTab].chart.options[eggHatchDateIdx].props.maxDate = () => parseISO(seasonBounds[1]);
      updateCurrentTabInformation({ tabInformation });
    } else if (selectedSubTab === 'European Corn Borer') {
      const tab = tabInformation.find(({ name }) => name === TAB_NAME);
      const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
      const seasonBounds = subTabChartOptions.find(({ id }) => id === 'european-corn-borer-season-bounds-selector').props.value;
      let generation = subTabChartOptions.find(({ id }) => id === 'european-corn-borer-generation-selector').props.value;
      if (generation.length === 0) generation = [1192];
      const generationThresholds = Array.from({ length: 10 }).map((_, idx) => (idx + 1) * generation[0]);
      newChartData = calcChartData(rawChartData[selectedLocation]['gddBase50'], generationThresholds, calcGddChartData, seasonBounds, TODAY);
      newChartData.generation = generation;
    } else {
      const baseNumber = tabsSharedState.gddBaseSelector.value[0];
      const targetBase = `gddBase${baseNumber}`;
      if (!(targetBase in rawChartData[selectedLocation])) {
        const locInfo = pastLocations[selectedLocation];
        const coords = `${locInfo.lng},${locInfo.lat}`;

        const elems = {
          loc: coords,
          grid: 'ncei-clim',
          sdate: '1951-01-01',
          edate: format(TODAY, 'yyyy-MM-dd'),
          elems: [{
            name: 'gdd',
            base: baseNumber
          }],
        };

        return fetchFromAcis(elems)
          .then(rawData => {
            const newRawChartData = { ...rawChartData };
            newRawChartData[selectedLocation][targetBase] = rawData;
            return () => setRawChartData(newRawChartData);
          });
      } else {
        newChartData = calcChartData(rawChartData[selectedLocation][targetBase], tabsSharedState.gddThresholdSelector.value, calcGddChartData, tabsSharedState.gddSeasonBoundsSelector.value, TODAY);
      }
    }
      
    return () => setChartData(newChartData);
  }
  return () => {};
}

export function updateOverlay({ selectedTab, selectedSubTab, overlayFilename }, _, { updateOverlayData }) {
  if (selectedTab === TAB_NAME) {
    let jsonFileName;
    if (selectedSubTab.includes('Base') || selectedSubTab.includes('Corn')) {
      jsonFileName = `${selectedSubTab.split(' ').join('_').toLowerCase()}.json`;
    } else if (overlayFilename) {
      return () => {};
    } else {
      jsonFileName = 'base_50.json';
    }
    const jsonFileDataKey = 'departures';
    return updateOverlayFromR2(jsonFileName, jsonFileDataKey, undefined, true)
      .then(d => () => updateOverlayData(d));
  }
  return () => {};
}

export function updateTitle({selectedSubTab, display, overlayDataDate, overlayFilename, tabsSharedState, chartData}) {
  try {
    let type;
    if (selectedSubTab.includes('Corn')) {
      type = selectedSubTab;
    } else {
      let base;
      if (display === 'map') {
        base = overlayFilename.slice(0,-5).split('_').map(p => p[0].toUpperCase() + p.slice(1)).join(' ');
      } else {
        base = typeof tabsSharedState.gddBaseSelector.value === 'function' ? selectedSubTab : `Base ${tabsSharedState.gddBaseSelector.value}`;
      }
      type = `${base} GDDs`;
    }

    let sdate, edate;
    if (display === 'map') {
      const dateObj = parseISO(overlayDataDate);
      sdate = format(new Date(dateObj.getFullYear(), 2, 1), 'LLL do, yyyy');
      edate = format(dateObj, 'LLL do, yyyy');
    } else {
      const datesArr = chartData.timeseries.dates;
      sdate = format(parseISO(datesArr[0]), 'LLL do, yyyy');
      edate = format(parseISO(datesArr.slice(-1)[0]), 'LLL do, yyyy');
    }

    return {
      type,
      sdate,
      edate
    };
  } catch {
    return null;
  }
};