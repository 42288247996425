import { format, parseISO } from "date-fns";

import { TODAY, updateOverlayFromR2, isInSeason, isPastThreshold, calcSeasonYear } from "../../../utilities";
import { calcCottonQualityChartData } from "./cotton-quality";
import { calcCaseStudy1ChartData } from "./case-study-1";

export const TAB_NAME = 'Extreme Heat';
export const DATA_NAME = 'mintMaxt';

const calcChartData = (temps, dataIdx, thresholds, seasonBounds, direction, TODAY) => {
  const compFn = isPastThreshold(thresholds, direction);
  const newChartData = {
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;

    if (isToday && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.toDate.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.years.push(parseInt(strDate.slice(0,4)));
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = temps.slice(idxYearStart);
    const seasonYear = calcSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1]);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    newChartData.yearlySeasons.fullSeason.push(null);
  }

  const selectedSeasonData = temps.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? temps.length : selectedSeasonIndices[1] + 1);
  newChartData.timeseries = selectedSeasonData.reduce((acc, dayData) => {
    acc.dates.push(dayData[0]);
    acc.current.push(dayData[dataIdx]);
    return acc;
  }, { dates: [], current: [] });

  return newChartData;
};

export function updateChartData({ selectedTab, selectedSubTab, selectedLocation, tabInformation, tabsSharedState, rawChartData }, _, { setChartData }) {
  if (
    selectedTab === TAB_NAME &&
    selectedLocation in rawChartData && DATA_NAME in rawChartData[selectedLocation]
  ) {
    let seasonBounds = tabsSharedState.extremeHeatSeasonBoundsSelector.value;
    if (typeof seasonBounds === 'function') seasonBounds = seasonBounds();
    
    let newChartData;
    if (selectedSubTab === 'Case Study 1') {
      const tab = tabInformation.find(({ name }) => name === TAB_NAME);
      const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
      const thresholds = subTabChartOptions.find(({ id }) => id === 'case-study-1-threshold-selector').props.value;
      newChartData = calcCaseStudy1ChartData(rawChartData[selectedLocation][DATA_NAME], thresholds, seasonBounds, TODAY);
    } else if (selectedSubTab === 'Cotton Quality') {
      const tab = tabInformation.find(({ name }) => name === TAB_NAME);
      const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
      seasonBounds = subTabChartOptions.find(({ id }) => id === 'cotton-quality-season-bounds-selector').props.value;
      const gddThresholds = subTabChartOptions.find(({ id }) => id === 'cotton-quality-gdd-range-selector').props.value;
      const optimalThresholds = subTabChartOptions.find(({ id }) => id === 'cotton-quality-optimal-range-selector').props.value;
      const extremeThreshold = subTabChartOptions.find(({ id }) => id === 'cotton-quality-extreme-temperature-selector').props.value;
      newChartData = calcCottonQualityChartData(rawChartData[selectedLocation].mintMaxt, rawChartData[selectedLocation].gddBase60, gddThresholds, optimalThresholds, extremeThreshold, seasonBounds, TODAY);
    } else {
      let thresholds = tabsSharedState.extremeHeatThresholdSelector.value;
      if (typeof thresholds === 'function') thresholds = thresholds({selectedSubTab})[0];
      const dataIdx = selectedSubTab.includes('Min') ? 1 : 2;
      newChartData = calcChartData(rawChartData[selectedLocation][DATA_NAME], dataIdx, thresholds, seasonBounds, 'above', TODAY);
    }

    return () => setChartData(newChartData);
  }
  return () => {};
}

export function updateOverlay({ selectedSubTab }, _, { updateOverlayData }) {
  let jsonFileName;
  if (selectedSubTab === 'Case Study 1') {
    jsonFileName = 'case_study_1.json';
  } else if (selectedSubTab === 'Cotton Quality') {
    jsonFileName = 'cotton_quality.json';
  } else {
    jsonFileName = `days_over_${selectedSubTab.slice(6,8)}_${selectedSubTab.slice(0,3).toLowerCase()}.json`;
  }
  const jsonFileDataKey = 'departures';
  return updateOverlayFromR2(jsonFileName, jsonFileDataKey, undefined, true)
    .then(d => () => updateOverlayData(d));
}

export function updateTitle({selectedSubTab, display, overlayDataDate, chartData}) {
  try {
    let sdate, edate, type, datesArr;
    if (selectedSubTab === 'Case Study 1' || selectedSubTab === 'Cotton Quality') {
      type = selectedSubTab;
      datesArr = chartData.timeseries.dates;
    } else {
      type = `Days With ${selectedSubTab.slice(0,3)} Over ${selectedSubTab.slice(6,8)}`;
      datesArr = chartData.timeseries.dates;
    }

    if (display === 'map') {
      const dateObj = parseISO(overlayDataDate);
      sdate = format(new Date(dateObj.getFullYear(), 0, 1), 'LLL do, yyyy');
      edate = format(parseISO(overlayDataDate), 'LLL do, yyyy');
    } else {
      sdate = format(parseISO(datesArr[0]), 'LLL do, yyyy');
      edate = format(parseISO(datesArr.slice(-1)[0]), 'LLL do, yyyy');
    }

    return {
      type,
      sdate,
      edate
    };
  } catch {
    return null;
  }
}