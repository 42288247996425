import { format } from 'date-fns';

import { TODAY } from '../../../utilities';
import { TAB_NAME, refreshChartData, updateOverlay, updateRawChartData, updateTitle } from './fns';
import { dailyPrecipOptions, precipAccumulationOptions, precipExtremeOccurrencesOptions, longestDryRunOptions } from './charts';

const info = {
  name: TAB_NAME,
  subTabs: [{
    name: '>0.5 inches'
  },{
    name: '>1.0 inches'
  },{
    name: 'Longest Run of Dry Days'
  }],
  chartInfo: {
    chartSelectorId: 'rainfallExtremesChartSelector',
    charts: [{
      name: 'Daily Precipitation and Threshold Exceedance',
      showIfSubTab: ['>0.5 inches', '>1.0 inches'],
      func: (chartData, address, tabInfoContextState, ...rest) => dailyPrecipOptions(chartData, address, tabInfoContextState.tabsSharedState.rainfallExtremesThresholdSelector.value[0])
    },{
      name: 'Precipitation Accumulation',
      showIfSubTab: ['>0.5 inches', '>1.0 inches'],
      func: precipAccumulationOptions
    },{
      name: 'Occurrences In Season',
      showIfSubTab: ['>0.5 inches', '>1.0 inches'],
      func: (chartData, address, tabInfoContextState, ...rest) => precipExtremeOccurrencesOptions(chartData, address, tabInfoContextState.tabsSharedState.rainfallExtremesSeasonBoundsSelector.value)
    },{
      name: 'Longest Run of Dry Days Annually',
      showIfSubTab: ['Longest Run of Dry Days'],
      func: longestDryRunOptions
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: updateOverlay,
    showLoading: true
  },{
    whenXChanges: ['selectedLocation', 'selectedTab'],
    handleChange: updateRawChartData,
    showLoading: true
  },{
    whenXChanges: ['rainfallExtremesThresholdSelector', 'rainfallExtremesSeasonBoundsSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: refreshChartData,
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'rainfall-extremes-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'rainfall-extremes-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'rainfall-extremes-charts',
      useSharedState: 'rainfallExtremesChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'rainfall-extremes-threshold-selector',
      useSharedState: 'rainfallExtremesThresholdSelector',
      props: {
        columnHeading: 'Heavy Rain',
        minNum: 1,
        maxNum: 1,
        minValue: 0,
        maxValue: 100,
        step: 0.1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'rainfall-extremes-season-bounds-selector',
      useSharedState: 'rainfallExtremesSeasonBoundsSelector',
    }]
  },
  componentFns: {
    title: updateTitle
  },
  textPanelInfo: []
};

export default info;
export const rainfallExtremesSharedStateDefinitions = {
  'rainfallExtremesChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'rainfallExtremesThresholdSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      const newValue = parseFloat(newSubTab.slice(1,4));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseFloat(selectedSubTab.slice(1,4))])
  },
  'rainfallExtremesSeasonBoundsSelector': {
    persist: false,
    initValue: [`${TODAY.getFullYear()}-01-01`, format(TODAY, 'yyyy-MM-dd')]
  }
};