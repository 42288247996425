import { format } from 'date-fns';

import { countAbove, TODAY } from '../../../utilities';
import { cattleTickTimeseriesOptions } from './cattle-tick';
import { avgAnnualTemp } from './charts';
import { TAB_NAME, temporalOptions, updateOverlay, updateChartData, updateTitle } from './fns';


const info = {
  name: TAB_NAME,
  subTabs: {
    'no-subheader-extreme-heat-group': [{
      name: 'Average Annual Temperature'
    }],
    'Case Studies': [{
      name: 'Cattle Tick'
    }]
  },
  chartInfo: {
    chartSelectorId: 'climatologicalChartSelector',
    charts: [{
      name: 'Average Temperature',
      showIfSubTab: ['Average Annual Temperature'],
      func: (chartData, address, tabInfoContextState, ...rest) => avgAnnualTemp(chartData, address, tabInfoContextState.tabsSharedState.climatologicalSeasonBoundsSelector.value.temporal)
    },{
      name: 'Cattle Tick Timeseries',
      showIfSubTab: ['Cattle Tick'],
      func: (chartData, address, ...rest) => cattleTickTimeseriesOptions(chartData, address)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: updateOverlay,
    showLoading: true
  },{
    whenXChanges: ['climatologicalSeasonBoundsSelector', 'rawChartData', 'cattle-tick-threshold-selector', 'selectedSubTab'],
    handleChange: updateChartData,
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'climatological-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'climatological-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'climatological-charts',
      useSharedState: 'climatologicalChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'temporalSelector',
      id: 'climatological-temporal-selector',
      showIfSubTab: ['Average Annual Temperature'],
      useSharedState: 'climatologicalSeasonBoundsSelector',
      props: {
        label: 'Change Timeframe',
        temporalProps: { options: temporalOptions }
      }
    },{
      component: 'thresholdRangeSelector',
      id: 'cattle-tick-threshold-selector',
      showIfSubTab: ['Cattle Tick'],
      props: {
        unit: '°F',
        minValue: -200,
        maxValue: 200,
        value: [68, 75],
        resetValues: [68, 75],
        showReset: true,
        automaticUpdateOnEdit: false
      }
    }]
  },
  componentFns: {
    title: updateTitle,
    aboveChartTextBlock: ({ selectedSubTab, display }) => {
      return (display === 'chart' && selectedSubTab === 'Cattle Tick') ? 'cattle-tick_above-charts.md' : null;
    }
  },
  textPanelInfo: [{
    name: 'Data Summary',
    onlyShowIfChart: true,
    onlyShowIfSubTab: ['Cattle Tick'],
    generateMarkdown: ({ chartData }) => {
      try {
        const idxOf2000 = chartData.timeseries.dates.findIndex(y => y === 2000);
        const arr1951To1999 = chartData.timeseries.current.slice(0, idxOf2000);
        const arr2000ToPresent = chartData.timeseries.current.slice(idxOf2000);
        
        const cntGtLower1951To1999 = countAbove(arr1951To1999, chartData.thresholds[0]);
        const cntGtUpper1951To1999 = countAbove(arr1951To1999, chartData.thresholds[1]);
        const cntInRange1951To1999 = cntGtLower1951To1999 - cntGtUpper1951To1999;
        const pctInRange1951To1999 = cntInRange1951To1999 / arr1951To1999.length * 100;
        
        const cntGtLower2000ToPresent = countAbove(arr2000ToPresent, chartData.thresholds[0]);
        const cntGtUpper2000ToPresent = countAbove(arr2000ToPresent, chartData.thresholds[1]);
        const cntInRange2000ToPresent = cntGtLower2000ToPresent - cntGtUpper2000ToPresent;
        const pctInRange2000ToPresent = cntInRange2000ToPresent / arr2000ToPresent.length * 100;
        
        const totalInRange = cntInRange1951To1999 + cntInRange2000ToPresent;
        const pctDiff = pctInRange2000ToPresent - pctInRange1951To1999;
        const pctChange = Math.round(pctInRange2000ToPresent / Math.max(pctInRange1951To1999,1) * 10000) / 100;
        const stayedSame = pctDiff === 0 ? 'remained constant': null;
        const incOrDec = pctDiff > 0 ? 'increased' : 'decreased';
        
        const numYears = chartData.timeseries.dates.length;
        const tempRangeAsText = `${chartData.thresholds[0]}°F - ${chartData.thresholds[1]}°F`;

        return `\nSince 1951, ${totalInRange} of ${numYears} years were associated with annual temperatures within the optimal range for cattle tick presence. Since 2000, the percentage of years with annual temperatures within the ${tempRangeAsText} temperature range has ${stayedSame || (incOrDec + ' by ' + Math.abs(pctChange) + '%')} compared to the 1951-1999 period.`;
      } catch {
        return '';
      }
    },
  },{
    name: 'Strategies to Minimize Effects of Cattle Ticks',
    markdownFilename: 'cattle-tick_strategies.md'
  },{
    name: 'Links',
    markdownFilename: 'cattle-tick_links.md'
  }]
};

export default info;
export const climatologicalSharedStateDefinitions = {
  'climatologicalChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'climatologicalSeasonBoundsSelector': {
    persist: false,
    initValue: { temporal: 'annual', seasonBounds: [`${TODAY.getFullYear()}-01-01`, format(TODAY, 'yyyy-MM-dd')]}
  },
};