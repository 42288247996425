import { format, parseISO } from "date-fns";

import {
  TODAY,
  updateOverlayFromR2,
  calcSeasonYear
} from "../../../utilities";
import { calcCattleTickChartData } from "./cattle-tick";


export const TAB_NAME = 'Climatological Averages';
export const DATA_NAME = 'mintMaxt'
export const temporalOptions = [
  { name: 'Annual', value: 'annual', start: ['01-01'], end: [] },
  { name: 'Seasonal', value: 'seasonal', start: [], end: [] },
  { name: 'January', value: '01', start: ['01-01'], end: ['01-31'] },
  { name: 'February', value: '02', start: ['02-01'], end: ['02-28', '02-29'] },
  { name: 'March', value: '03', start: ['03-01'], end: ['03-31'] },
  { name: 'April', value: '04', start: ['04-01'], end: ['04-30'] },
  { name: 'May', value: '05', start: ['05-01'], end: ['05-31'] },
  { name: 'June', value: '06', start: ['06-01'], end: ['06-30'] },
  { name: 'July', value: '07', start: ['07-01'], end: ['07-31'] },
  { name: 'August', value: '08', start: ['08-01'], end: ['08-31'] },
  { name: 'September', value: '09', start: ['09-01'], end: ['09-30'] },
  { name: 'October', value: '10', start: ['10-01'], end: ['10-31'] },
  { name: 'November', value: '11', start: ['11-01'], end: ['11-30'] },
  { name: 'December', value: '12', start: ['12-01'], end: ['12-31'] },
];

function calcAvgTemp(data) {
  const aggregated = data.reduce((acc, [date, mint, maxt]) => {
    acc.count++;
    acc.sum += ((mint + maxt) / 2);
    return acc;
  }, { count: 0, sum: 0 });
  return Math.round((aggregated.sum / aggregated.count) * 100) / 100;
}

function calcChartData(rawData, seasonBounds) {
  const newChartData = {
    finalDate: rawData.slice(-1)[0][0],
    years: [],
    annual: [],
    seasonal: {
      years: [],
      values: []
    }
  };
  
  const annualStartDay = '01-01';
  const annualEndDay = TODAY.toISOString().slice(5,10);
  let annualStartIdx = null;
  let annualEndIdx = 0;
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  let seasonStartIdx = null;
  let seasonEndIdx = 0;

  let activeMonth = { month: null, data: [] };
  for (let i = 0; i < rawData.length; i++) {
    const strDate = rawData[i][0];
    const currDay = strDate.slice(5,10);
    const currMonth = strDate.slice(5,7);
    const currYear = strDate.slice(0,4);

    if (currYear !== newChartData.years.slice(-1)[0]) {
      newChartData.years.push(currYear);
    }
    
    if (currMonth !== activeMonth.month) {
      if (activeMonth.data.length) {
        if (!(currMonth in newChartData)) {
          newChartData[currMonth] = [];
        }

        newChartData[currMonth].push(calcAvgTemp(activeMonth.data));
      }

      activeMonth = { month: currMonth, data: [] }
    }
    activeMonth.data.push(rawData[i]);
    
    
    if (currDay === annualStartDay) {
      annualStartIdx = i;
    }

    if (currDay === annualEndDay) {
      annualEndIdx = i;

      if (annualStartIdx !== null) {
        const chunk = rawData.slice(annualStartIdx, annualEndIdx + 1);
        newChartData.annual.push(calcAvgTemp(chunk));
      }
    }

    if (currDay === seasonStartDay) {
      seasonStartIdx = i;
    }

    if (currDay === seasonEndDay) {
      seasonEndIdx = i;
      
      if (seasonStartIdx !== null) {
        const chunk = rawData.slice(seasonStartIdx, seasonEndIdx + 1);
        newChartData.seasonal.years.push(chunk.slice(-1)[0][0].slice(0,4));
        newChartData.seasonal.values.push(calcAvgTemp(chunk));
      }
    }
  }

  if (activeMonth.month !== null && activeMonth.data.length) {
    if (!(activeMonth.month in newChartData)) {
      newChartData[activeMonth.month] = [];
    }

    newChartData[activeMonth.month].push(calcAvgTemp(activeMonth.data));
  }

  if (annualStartIdx !== null && (annualEndIdx === null || annualStartIdx > annualEndIdx)) {
    const chunk = rawData.slice(annualStartIdx);
    newChartData.annual.push(calcAvgTemp(chunk));
  }

  if (seasonStartIdx !== null && (seasonEndIdx === null || seasonStartIdx > seasonEndIdx)) {
    const chunk = rawData.slice(seasonStartIdx);
    const seasonYear = calcSeasonYear(chunk[chunk.length - 1][0], seasonBounds[0], seasonBounds[1]);
    newChartData.seasonal.years.push(seasonYear);
    newChartData.seasonal.values.push(calcAvgTemp(chunk));
  }

  return newChartData;
}

export function updateChartData({ selectedTab, selectedSubTab, selectedLocation, tabsSharedState, tabInformation, rawChartData }, _, { setChartData }) {
  if (
    selectedTab === TAB_NAME &&
    selectedLocation in rawChartData &&
    DATA_NAME in rawChartData[selectedLocation]
  ) {
    let seasonBounds = tabsSharedState.climatologicalSeasonBoundsSelector.value.seasonBounds;
    if (typeof seasonBounds === 'function') seasonBounds = seasonBounds();

    let newChartData;
    if (selectedSubTab === 'Cattle Tick') {
      const tab = tabInformation.find(({ name }) => name === TAB_NAME);
      const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
      const thresholds = subTabChartOptions.find(({ id }) => id === 'cattle-tick-threshold-selector').props.value;
      newChartData = calcCattleTickChartData(rawChartData[selectedLocation][DATA_NAME], thresholds, TODAY);
    } else {
      newChartData = calcChartData(rawChartData[selectedLocation][DATA_NAME], seasonBounds);
    }
    return () => setChartData(newChartData);
  }
  return () => {};
}

export function updateOverlay({ selectedSubTab }, _, { updateOverlayData }) {
  let jsonFileName;
  if (selectedSubTab === 'Cattle Tick') {
    jsonFileName = 'cattle_tick.json';
  } else {
    jsonFileName = selectedSubTab.split(' ').join('_').toLowerCase() + '.json';
  }
  const jsonFileDataKey = 'departures';
  return updateOverlayFromR2(jsonFileName, jsonFileDataKey, undefined, true)
    .then(d => () => updateOverlayData(d));
}

export function updateTitle({ display, overlayDataDate, chartData, tabsSharedState, selectedSubTab }) {
  try {
    let sdate, edate, type;      
    if (display === 'map') {
      const dateObj = parseISO(overlayDataDate);
      sdate = format(new Date(dateObj.getFullYear(), 0, 1), 'LLL do, yyyy');
      edate = format(parseISO(overlayDataDate), 'LLL do, yyyy');
      type = 'Annual Average Temperature';
    } else if (selectedSubTab === 'Cattle Tick') {
      sdate = format(parseISO(tabsSharedState.climatologicalSeasonBoundsSelector.value.seasonBounds[0]), 'LLL do');
      edate = format(parseISO(tabsSharedState.climatologicalSeasonBoundsSelector.value.seasonBounds[1]), 'LLL do');
    } else {
      const temporalName = temporalOptions.find(obj => obj.value === tabsSharedState.climatologicalSeasonBoundsSelector.value.temporal).name;
      if (temporalName === 'Annual') {
        type = `${temporalName} Average Temperature`;
        sdate = format(parseISO(tabsSharedState.climatologicalSeasonBoundsSelector.value.seasonBounds[0]), 'LLL') + ' 1st';
        edate = format(chartData.finalDate ? parseISO(chartData.finalDate) : TODAY, 'LLL do');
      } else if (temporalName === 'Seasonal') {
        type = `${temporalName} Average Temperature`;
        sdate = format(parseISO(tabsSharedState.climatologicalSeasonBoundsSelector.value.seasonBounds[0]), 'LLL do');
        edate = format(parseISO(tabsSharedState.climatologicalSeasonBoundsSelector.value.seasonBounds[1]), 'LLL do');
      } else {
        type = `Average Temperature`;
        sdate = '';
        edate = temporalName;
      }
    }

    if (selectedSubTab === 'Cattle Tick') {
      type = 'Cattle Tick';
    }        

    return {
      type,
      sdate,
      edate
    };
  } catch {
    return null;
  }
}