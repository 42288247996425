import roundXDigits from './round';

function calculateAvgDaysIntoSeason(arr) {
  const filteredArr = arr.filter(v => v !== null);
  return Math.floor(filteredArr.reduce((a,b) => a + b, 0) / filteredArr.length)
}

export function calcChartData(values, thresholds, chartDataFunc, seasonBounds, today) {
  if (values.length) {
    const newChartData = chartDataFunc(
      [...values],
      [...thresholds], 
      typeof seasonBounds === 'function' ? seasonBounds() : seasonBounds,
      today
    );

    if ('maxs' in newChartData) {
      newChartData.maxs.subsetSize = 10;
      newChartData.maxs.valuesRunningAverage = calcRunningAverage(
        newChartData.maxs.subsetSize,
        newChartData.maxs.values
      );
      newChartData.maxs.dateOccurredRunningAverage = calcRunningAverage(
        newChartData.maxs.subsetSize,
        newChartData.maxs.dayOfSeason
      );
  
      newChartData.maxs.avgDaysIntoSeason = calculateAvgDaysIntoSeason(newChartData.maxs.daysIntoSeason);
    }

    newChartData.onToday.subsetSize = 10;
    newChartData.onToday.valuesRunningAverage = calcRunningAverage(
      newChartData.onToday.subsetSize,
      newChartData.onToday.values
    );

    newChartData.thresholds = newChartData.thresholds.map((tObj) => {
      tObj.avgDaysIntoSeason = calculateAvgDaysIntoSeason(tObj.daysIntoSeason);
      return tObj;
    });

    return newChartData;
  }

  return null;
}

export const calcRunningAverage = (subsetSize, arr) => {
  const runningAverage = [];
  for (let i = subsetSize; i <= arr.length; i++) {
    let sum = 0;
    let x = i - subsetSize;
    while (x < i) {
      sum += arr[x];
      x++;
    }
    runningAverage.push(roundXDigits(sum / subsetSize, 1));
  }
  return runningAverage;
};