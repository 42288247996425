import { format, parseISO } from "date-fns";

import { isInSeason, TODAY, calcSeasonYear, updateOverlayFromR2, fetchFromAcis } from "../../../utilities";

export const TAB_NAME = 'Rainfall Extremes';
export const DATA_NAME = 'pcpn';

function transpose(matrix) {
  const rows = matrix.length, cols = matrix[0].length;
  const grid = [];
  for (let j = 0; j < cols; j++) {
    grid[j] = Array(rows);
  }
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      grid[j][i] = matrix[i][j];
    }
  }
  return grid;
}

const longestLessThanRun = (arr, target) => {
  let maxRunLength = 0;
  let currentRunLength = 0;

  for (let num of arr) {
    if (num < target) {
      currentRunLength++;
      maxRunLength = Math.max(maxRunLength, currentRunLength);
    } else {
      currentRunLength = 0;
    }
  }

  return maxRunLength;
}

function refreshRainfallExtremesChartData(pcpns, threshold, seasonBounds) {
  const newChartData = {
    timeseries: {
      dates: [],
      current: [],
      normal: [],
      highest: { year: null, data: [] },
      lowest: { year: null, data: [] }
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: [],
      longestDryRun: []
    }
  };

  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);

  const dataBySeason = [];

  let idxYearStart = null;
  let lastIdxProcessed = null;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < pcpns.length; i++) {
    const strDate = pcpns[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;

    if (isToday && idxYearStart !== null) {
      const seasonData = pcpns.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.toDate.push(seasonData.filter(([_, val]) => val >= threshold).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = pcpns.slice(idxYearStart, i + 1);
      const seasonYear = parseInt(seasonData[seasonData.length - 1][0].slice(0,4));

      // Calculate season precip sum, store for later to calculate normals, track highest/lowest accumulation year
      let precipSum = 0;
      const precipSums = seasonData.map(([_, val]) => {
        precipSum += val;
        return precipSum;
      });
      dataBySeason.push(precipSums);
      if (newChartData.timeseries.highest.year === null || precipSum > newChartData.timeseries.highest.data.slice(-1)[0]) {
        newChartData.timeseries.highest = { year: seasonYear, data: precipSums };
      }
      if (newChartData.timeseries.lowest.year === null || precipSum < newChartData.timeseries.lowest.data.slice(-1)[0]) {
        newChartData.timeseries.lowest = { year: seasonYear, data: precipSums };
      }
      
      newChartData.yearlySeasons.years.push(seasonYear);
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter(([_, val]) => val >= threshold).length);
      newChartData.yearlySeasons.longestDryRun.push(longestLessThanRun(seasonData.map(arr => arr[1]), 0.01));
      lastIdxProcessed = i;
    }

    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  let regularSeasonDates = [];
  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = pcpns.slice(idxYearStart);
    regularSeasonDates = currentSeasonData.map(([d,v]) => d);
    const seasonYear = calcSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1]);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter(([_, val]) => val >= threshold).length);
    newChartData.yearlySeasons.fullSeason.push(null);
    newChartData.yearlySeasons.longestDryRun.push(longestLessThanRun(currentSeasonData.map(arr => arr[1]), 0.01));
  } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
    // Remove current season from dataBySeason if it is in there, so it isnt included in the normals
    regularSeasonDates = pcpns.slice(idxYearStart, lastIdxProcessed).map(([d,v]) => d);
    dataBySeason.pop();
  }

  // If 2/29 is in season range, handle leap years:
  //   If timeseries includes 2/29, any non leap year gets 3/1 duplicated
  //   If timeseries does not include 2/29, any leap year gets 2/29 deleted
  const indexOfLeapDay = regularSeasonDates.indexOf(`${regularSeasonDates[0].slice(0,4)}-02-29`);
  const seasonLengths = dataBySeason.map(arr => arr.length);
  const lengthOfLeapSeason = Math.max(...seasonLengths);
  const lengthOfNonLeapSeason = Math.min(...seasonLengths);
  if (lengthOfLeapSeason !== lengthOfNonLeapSeason) {
    if (indexOfLeapDay >= 0) {
      for (let i = 0; i < dataBySeason.length; i++) {
        const seasonArr = dataBySeason[i];
        if (seasonArr.length !== lengthOfLeapSeason) {
          seasonArr.splice(indexOfLeapDay, 0, seasonArr[indexOfLeapDay]);
        }
      }
    } else {
      for (let i = 0; i < dataBySeason.length; i++) {
        const seasonArr = dataBySeason[i];
        if (seasonArr.length === lengthOfLeapSeason) {
          seasonArr.splice(indexOfLeapDay, 1);
        }
      }
    }
  }

  const selectedSeasonData = pcpns.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? pcpns.length : selectedSeasonIndices[1] + 1);
  selectedSeasonData.forEach(([date, value]) => {
    newChartData.timeseries.dates.push(date);
    newChartData.timeseries.current.push(value);
  });
  
  // Calculate normals and construct obj of chart info for timeseries/normal/highest/lowest chart
  const dataByDate = transpose(dataBySeason).slice(0, newChartData.timeseries.dates.length);
  const normals = dataByDate.map(dateArr => dateArr.reduce((a,b) => a + b) / dateArr.length);
  newChartData.timeseries.normal = normals;
  newChartData.timeseries.highest.data = newChartData.timeseries.highest.data.slice(0, newChartData.timeseries.dates.length);
  newChartData.timeseries.lowest.data = newChartData.timeseries.lowest.data.slice(0, newChartData.timeseries.dates.length);

  return newChartData;
};

export function refreshChartData({ selectedTab, selectedLocation, tabsSharedState, rawChartData }, _, { setChartData }) {
  if (selectedTab === TAB_NAME && selectedLocation in rawChartData && DATA_NAME in rawChartData[selectedLocation]) {
    const newChartData = refreshRainfallExtremesChartData(
      rawChartData[selectedLocation][DATA_NAME],
      tabsSharedState.rainfallExtremesThresholdSelector.value,
      tabsSharedState.rainfallExtremesSeasonBoundsSelector.value
    );
    return () => setChartData(newChartData);
  }
  return () => {};
}

export function updateRawChartData({ selectedTab, selectedLocation, pastLocations, rawChartData }, _, { setRawChartData, fetchingBaseData }) {
  if (selectedTab === TAB_NAME && !fetchingBaseData) {
    let newRawChartData = { ...rawChartData };
    if (!(selectedLocation in newRawChartData)) {
      newRawChartData = { [selectedLocation]: {} };
    }
    
    if (!(DATA_NAME in newRawChartData[selectedLocation])) {
      const locInfo = pastLocations[selectedLocation];
      const coords = `${locInfo.lng},${locInfo.lat}`;

      const elems = {
        loc: coords,
        grid: 'ncei-clim',
        sdate: '1951-01-01',
        edate: format(TODAY, 'yyyy-MM-dd'),
        elems: [{
          name: 'pcpn'
        }]
      };

      return fetchFromAcis(elems)
        .then(rawData => {
          newRawChartData[selectedLocation][DATA_NAME] = rawData;
          return () => setRawChartData(newRawChartData);
        });
      }
  }
  
  return () => {};
}

export function updateOverlay({ selectedSubTab }, _, { updateOverlayData }) {
  const jsonFileName = selectedSubTab[0] === '>' ? `${selectedSubTab.slice(1,4)}_inches.json` : 'longest_dry_run.json';
  const jsonFileDataKey = 'departures';
  return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
    .then(d => () => updateOverlayData(d));
}

export function updateTitle({selectedSubTab, display, overlayDataDate, chartData}) {
  try {
    let sdate, edate;
    if (display === 'map') {
      const dateObj = parseISO(overlayDataDate);
      sdate = format(new Date(dateObj.getFullYear(), 0, 1), 'LLL do, yyyy');
      edate = format(parseISO(overlayDataDate), 'LLL do, yyyy');
    } else {
      sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
      edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
    }

    const type = selectedSubTab[0] === '>' ? `${selectedSubTab} Precipitation` : selectedSubTab;
    return {
      type,
      sdate,
      edate
    }
  } catch {
    return null;
  }
}