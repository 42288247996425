import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../../components/tooltip-season/tooltip-season.component';
import { COLORS, isPastThreshold } from '../../../utilities';

export function extremeHeatTimeseriesOptions(chartData, address, threshold, direction, titlePart) {
  const compFn = isPastThreshold(threshold, direction);
  const timeseries = chartData.timeseries.current.map(temp => compFn(temp) ? temp : null);
  const dates = chartData.timeseries.dates.slice(0, timeseries.length).map(str => str.slice(5));

  let min = 0;
  let max = 0;
  let zones = [];
  if (direction === 'below') {
    min = Math.min(...timeseries, threshold - 5);
    max = threshold + 5;
    zones = [{
      value: threshold,
      color: COLORS.lightBlue
    },{
      color: COLORS.darkRed
    }];
  } else {
    min = threshold - 5;
    max = Math.max(...timeseries, threshold + 5);
    zones = [{
      color: COLORS.darkRed
    },{
      value: threshold,
      color: COLORS.lightBlue
    }];
  }

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    title: {
      text: `${titlePart} Temperatures Throughout Season`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        groupPadding: 0,
        pointPadding: 0,
        threshold,
        zones
      }
    ],
    xAxis: {
      categories: dates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            value: threshold,
            label: {
              text: `${threshold}°F`,
              align: 'right',
              style: {
                color: 'rgb(150,150,150)',
              },
            },
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const val = point.y;
        const date = point.x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={compFn(val) ? COLORS.lightBlue : COLORS.darkRed} />

            <div className='mc-container'>
              <div className='mc-number'>{Math.round(val * 100) / 100}</div>
              <div className='mc-units'>°F</div>
            </div>
          </div>
        );
      },
    },
  };
}

export function extremeHeatAnnualOptions(chartData, address, seasonBounds) {
  const { years, toDate, toDateDate, fullSeason } = chartData.yearlySeasons;
  const min = 0;
  const max = Math.max(...fullSeason, ...toDate);

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  const finalCurrentYearDate = chartData.timeseries.dates.slice(-1)[0];
  let finalDataDate = new Date(finalCurrentYearDate) < new Date(toDateDate) ? finalCurrentYearDate : toDateDate;
  finalDataDate = finalDataDate.slice(5,10).replace('-','/')

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: `Occurrences for the Season: ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'At End of Season',
        color: COLORS.lightGray,
        opacity: 0.6,
        borderColor: COLORS.lightGray,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: COLORS.lightBlue,
        pointPadding: 0.125
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1)),
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        const isCurrentYear = date === years.slice(-1)[0];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.lightBlue} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name === 'To Date' ? `To ${isCurrentYear ? finalDataDate : toDateDate.slice(5,10).replace('-','/')}` : point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}