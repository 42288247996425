import { renderToStaticMarkup } from 'react-dom/server';
import { parseISO, format } from 'date-fns';

import TooltipSeason from '../../../components/tooltip-season/tooltip-season.component';
import TooltipThresholds from '../../../components/tooltip-thresholds/tooltip-thresholds.component';
import { roundXDigits, COLORS } from '../../../utilities';

export function accumulationThroughoutSeasonOptions(chartData, address, dataName) {
  const timeseries = [...chartData.timeseries.current];
  const dates = chartData.timeseries.dates.map(d => d.slice(5,10));
  const min = 0;
  const max = Math.max(...timeseries);
  
  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: `${dataName} Accumulation Throughout Season`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        color: COLORS.darkRed,
      }
    ],
    xAxis: {
      categories: dates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: dataName,
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const gdds = point.y;
        const date = point.x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.darkRed} />

            <div className='mc-container'>
              <div className='mc-number'>{gdds}</div>
              <div className='mc-units'>{dataName}</div>
            </div>
          </div>
        );
      },
    },
  };
}

export function chillUnitsAchievedOptions(chartData, address, dataName) {
  const min = Math.min(...chartData.onToday.values.filter((val) => val));
  const max = Math.max(...chartData.onToday.values);
  const maxData = chartData.onToday.values.map((days) => {
    const obj = {
      y: days,
      color: COLORS.lightBlue,
    };

    if (days === min) {
      obj.color = 'red';
    } else if (days === max) {
      obj.color = 'blue';
    }

    return obj;
  });

  const avg_max = Math.round(
    chartData.onToday.values.reduce((acc, val) => (acc += val)) /
      chartData.onToday.values.length
  );

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: `Seasonal ${dataName} Achieved`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: maxData,
        name: `Maximum ${dataName}`,
        color: COLORS.lightBlue,
        zIndex: 1,
      },
      {
        data: new Array(chartData.onToday.subsetSize - 1)
          .fill(null)
          .concat(chartData.onToday.valuesRunningAverage),
        name: '10-year Running Average',
        color: COLORS.darkBlue,
      },
    ],
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: dataName,
          style: {
            color: COLORS.lightBlue,
          },
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            label: {
              text: 'Avg. Max',
              align: 'right',
              style: {
                color: 'rgb(150,150,150)',
              },
            },
            value: avg_max,
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const chillUnits = point.y;
        const year = point.x;
        const dateOccurred = chartData.onToday.dateOccurred;

        const diffFromAvg = chillUnits - avg_max;
        let relativeToAverage = '';
        if (diffFromAvg > 0) {
          relativeToAverage = '+';
        }

        const avg = this.points[1];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={year} color={COLORS.lightBlue} />

            <div className='mc-occurred'>
              Occurred on {format(parseISO(dateOccurred), 'MMM do')}
            </div>

            <div className='mc-container'>
              <div className='mc-number'>{roundXDigits(chillUnits, 1)}</div>
              <div className='mc-units'>{dataName}</div>
            </div>

            <div className='mc-container'>
              <div className='mc-number'>
                {relativeToAverage}
                {roundXDigits(diffFromAvg, 1)}
              </div>
              <div className='mc-units'>{dataName} from average</div>
            </div>

            {avg && (
              <div className='mc-container'>
                <div className='mc-number'>{avg.y}</div>
                <div className='mc-units'>
                  {year + 1 - chartData.onToday.subsetSize}-{year} average
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  };
}

export function maxChillUnitsOptions(chartData, address) {
  const min = Math.min(...chartData.maxs.dayOfSeason);
  const max = Math.max(...chartData.maxs.dayOfSeason);

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: 'Date of Seasonal Maximum Chill Units',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.maxs.dayOfSeason,
        name: 'Date Occurred',
        color: COLORS.lightGray,
        zIndex: 1,
      },
      {
        data: new Array(chartData.maxs.subsetSize - 1)
          .fill(null)
          .concat(chartData.maxs.dateOccurredRunningAverage),
        name: '10-year Running Average',
        color: COLORS.darkGray,
      },
    ],
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        tickInterval: 30 * 24 * 3600 * 1000,
        gridLineWidth: 0,
        alignTicks: false,
        type: 'datetime',
        title: {
          text: 'Date Occurred',
          style: {
            color: COLORS.lightGray,
          },
        },
        labels: {
          format: '{value:%b}',
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            label: {
              text: `New Year's Day`,
              style: {
                color: 'rgb(150,150,150)',
              },
            },
            value: Date.parse('2021-01-01'),
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const year = this.points[0].x;
        const yearIdx = this.points[0].point.x;
        const dateOccurred = chartData.maxs.dateOccurred[yearIdx];
        const daysIntoSeason = chartData.maxs.daysIntoSeason[yearIdx];

        const diffFromAvg =
          daysIntoSeason - chartData.maxs.avgDaysIntoSeason;
        let relativeToAverage = '';
        if (diffFromAvg > 0) {
          relativeToAverage = '+';
        }

        const avg = this.points[1];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={year} color={COLORS.lightGray} />

            <div className='mc-occurred'>
              Occurred on {format(parseISO(dateOccurred), 'MMM do')}
            </div>

            <div className='mc-container'>
              <div className='mc-number'>{daysIntoSeason}</div>
              <div className='mc-units'>days into season</div>
            </div>

            <div className='mc-container'>
              <div className='mc-number'>
                {relativeToAverage}
                {diffFromAvg}
              </div>
              <div className='mc-units'>days from average</div>
            </div>

            {avg && (
              <div className='mc-container'>
                <div className='mc-number'>
                  {format(new Date(avg.y), 'MMM d')}
                </div>
                <div className='mc-units'>
                  {year + 1 - chartData.maxs.subsetSize}-{year} average
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  };
}

export function thresholdExceedanceOptions(chartData, address, dataType) {
  let min = Infinity;
  let max = 0;

  const series = chartData.thresholds
    .map((t, i) => {
      const tmin = Math.min(...t.dayOfSeason.filter((val) => val));
      const tmax = Math.max(...t.dayOfSeason);

      if (tmin < min) min = tmin;
      if (tmax > max) max = tmax;

      const data = t.dayOfSeason.map((val) => {
        const obj = {
          y: val,
          color: COLORS.thresholds[i],
        };

        if (val === tmin) {
          obj.color = 'blue';
        } else if (val === tmax) {
          obj.color = 'red';
        }

        return obj;
      });

      return {
        data,
        name: `${t.threshold} ${dataType} Threshold`,
        color: COLORS.thresholds[i],
      };
    })
    .reverse();

  return {
    chart: {
      marginRight: 20,
    },
    lang: {
      noData: 'No thresholds are defined',
    },
    title: {
      text: 'Seasonal Date of Threshold Exceedance',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series,
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: {
      gridLineWidth: 0,
      alignTicks: false,
      tickInterval: 10 * 24 * 3600 * 1000,
      type: 'datetime',
      min,
      max,
      title: {
        text: 'Date Occurred',
        style: {
          color: 'rgb(0,150,0)',
        },
      },
      labels: {
        step: 4,
        format: '{value:%b}',
        style: {
          fontFamily:
            'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
        },
      },
      plotLines: [
        {
          color: 'rgb(200,200,200)',
          label: {
            text: `New Year's Day`,
            style: {
              color: 'rgb(150,150,150)',
            },
          },
          value: Date.parse('2021-01-01'),
        },
      ],
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const year = this.points[0].x;
        const yearIdx = this.points[0].point.x;

        return renderToStaticMarkup(
          <div>
            <TooltipSeason year={year} color={COLORS.thresholds[1]} />
            <TooltipThresholds
              thresholds={chartData.thresholds}
              yearIdx={yearIdx}
            />
          </div>
        );
      },
    },
  };
}