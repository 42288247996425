import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../../components/tooltip-season/tooltip-season.component';
import { COLORS, isInSeason, calcSeasonYear } from '../../../utilities';

export function caseStudy1TimeseriesOptions(chartData, address) {
  const min = Math.min(...chartData.timeseries.current, chartData.thresholds[0] - 5);
  const max = Math.max(...chartData.timeseries.current, chartData.thresholds[1] + 5);
  
  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: 'Timeseries Option 1: Highlighted Range',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.timeseries.current,
        name: 'Current Season',
        zones: [{
          value: chartData.thresholds[0],
          color: COLORS.lightBlue
        },{
          value: chartData.thresholds[1],
          color: 'rgb(100,100,100)'
        },{
          color: COLORS.darkRed
        }]
      }
    ],
    xAxis: {
      categories: chartData.timeseries.dates.map(d => d.slice(5,10).replace('-', '/')),
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        title: {
          text: 'Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands: [{
          from: chartData.thresholds[0],
          to: chartData.thresholds[1]
        }]
      },
    ],
    legend: {
      enabled: false,
    },
  };
}

export function caseStudy1AnnualOptions(chartData, address, seasonBounds) {
  const { years, toDate, toDateDate, fullSeason } = chartData.yearlySeasons;
  const min = 0;
  const max = Math.max(...fullSeason, ...toDate);

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  const finalCurrentYearDate = chartData.timeseries.dates.slice(-1)[0];
  let finalDataDate = new Date(finalCurrentYearDate) < new Date(toDateDate) ? finalCurrentYearDate : toDateDate;
  finalDataDate = finalDataDate.slice(5,10).replace('-','/')

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: `Occurrences in Range for the Season: ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'At End of Season',
        color: COLORS.lightGray,
        opacity: 0.6,
        borderColor: COLORS.lightGray,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: COLORS.lightBlue,
        pointPadding: 0.125
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1)),
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        const isCurrentYear = date === years.slice(-1)[0];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.lightBlue} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name === 'To Date' ? `To ${isCurrentYear ? finalDataDate : toDateDate.slice(5,10).replace('-','/')}` : point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

export const calcCaseStudy1ChartData = (temps, thresholds, seasonBounds, TODAY) => {
  // Count of days with max temp in threshold range
  function isInRange(val) {
    return thresholds[0] <= val && val <= thresholds[1];
  }

  const newChartData = {
    thresholds,
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;

    if (isToday && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);

      newChartData.yearlySeasons.toDate.push(seasonData.filter((dayData) => isInRange(dayData[2])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.years.push(parseInt(strDate.slice(0,4)));
      
      // Count of days with max temp in threshold range
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter((dayData) => isInRange(dayData[2])).length);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = temps.slice(idxYearStart);
    const seasonYear = calcSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1]);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter((dayData) => isInRange(dayData[2])).length);
    newChartData.yearlySeasons.fullSeason.push(null);
  }

  const selectedSeasonData = temps.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? temps.length : selectedSeasonIndices[1] + 1);
  newChartData.timeseries = selectedSeasonData.reduce((acc, dayData) => {
    acc.dates.push(dayData[0]);
    acc.current.push(dayData[2]);
    return acc;
  }, { dates: [], current: [] });

  return newChartData;
};