import { format } from 'date-fns';

import { TODAY, calcInitStartDate } from '../../../utilities';
import { TAB_NAME, updateOverlay, updateChartData, updateTitle } from './fns';
import { extremeColdTimeseriesOptions, extremeColdAnnualOptions } from './charts';


const info = {
  name: TAB_NAME,
  subTabs: [
    {
      name: 'Min < 32°F'
    },{
      name: 'Max < 32°F'
    },
  ],
  chartInfo: {
    chartSelectorId: 'extremeColdChartSelector',
    charts: [{
      name: 'Cold Temperature Exceedance',
      func: (chartData, address, tabInfoContextState, selectedSubTab) => {
        const thresholdValue = tabInfoContextState.tabsSharedState.extremeColdThresholdSelector.value;
        let threshold = typeof thresholdValue === 'function' ? thresholdValue({ selectedSubTab })[0] : thresholdValue[0];
        return extremeColdTimeseriesOptions(chartData, address, threshold, 'below', selectedSubTab.slice(0,3));
      }
    },{
      name: 'Occurrences in Season',
      func: (chartData, address, tabInfoContextState, ...rest) => extremeColdAnnualOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeColdSeasonBoundsSelector.value)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: updateOverlay,
    showLoading: true
  },{
    whenXChanges: ['extremeColdThresholdSelector', 'extremeColdSeasonBoundsSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: updateChartData,
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'extreme-cold-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'extreme-cold-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'extreme-cold-charts',
      useSharedState: 'extremeColdChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'extreme-cold-threshold-selector',
      useSharedState: 'extremeColdThresholdSelector',
      props: {
        columnHeading: 'Days Under',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        step: 1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'extreme-cold-season-bounds-selector',
      useSharedState: 'extremeColdSeasonBoundsSelector'
    }]
  },
  componentFns: {
    title: updateTitle
  },
  textPanelInfo: [{
    name: 'How To Use Tool',
    markdownFilename: 'how-to-use_default.md'
  },{
    name: 'About The Data',
    markdownFilename: 'about-the-data_default.md'
  }]
};
export default info;

export const extremeColdSharedStateDefinitions = {
  'extremeColdChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'extremeColdThresholdSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      const newValue = parseInt(newSubTab.slice(6,8));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.slice(6,8))])
  },
  'extremeColdSeasonBoundsSelector': {
    persist: false,
    initValue: [calcInitStartDate('07-01', TODAY), format(TODAY, 'yyyy-MM-dd')]
    // initValue: () => {
    //   const isCurrentSeasonYear = isWithinInterval(TODAY, {
    //     start: new Date(TODAY.getFullYear(), 0, 1),
    //     end: new Date(TODAY.getFullYear(), 5, 31)
    //   });
    //   const startYear = TODAY.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
      
    //   return [`${startYear}-07-01`, format(TODAY, 'yyyy-MM-dd')];
    // }
  }
};