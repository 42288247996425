import { renderToStaticMarkup } from 'react-dom/server';
import { format, parseISO, addDays, getTime } from 'date-fns';

import TooltipSeason from '../../../components/tooltip-season/tooltip-season.component';
import { COLORS } from '../../../utilities';

import { calculateGddAccumulations, calcSeasonDates } from "../../../utilities";

function baskervilleEmin(mint, maxt, baset) {
  let dd;
  const avgt = (mint + maxt) / 2;
  if (mint >= baset) {
    dd = Math.max(avgt - baset, 0);
  } else if (maxt <= baset) {
    dd = 0;
  } else {
    const tamt = (maxt - mint) /  2;
    const t1 = Math.asin((baset - avgt) /  tamt);
    dd =  Math.round(Math.max((((tamt * Math.cos(t1)) - ((baset - avgt) * ((3.14 / 2.0) - t1))) / 3.14), 0) * 100) / 100;
  }
  return dd;
}

function calculatePercentEmergence(gddF) {
  const gddC = gddF / 1.8;
  return 1 - Math.exp(-1*[(gddC+31.06) / 290.7]**2);
}

function calcTimeseries(baskervilleEminGdds) {
  return baskervilleEminGdds.reduce((acc, [date, gddF], i) => {
    acc.dates.push(date);
    const perc = calculatePercentEmergence(gddF) * 100;
    acc.current.push(Math.round(Math.min(perc, 100) * 100) / 100);
    if (perc >= 50 && acc.halfEmergedIdx === null) {
      acc.halfEmergedIdx = i;
    }
    return acc;
  }, { dates: [], current: [], halfEmergedIdx: null });
}

function processSeason(temps, gdds, eggHatchDate, iStart, iEnd) {
  const halfEmergenceGdd = 378;
  const seasonTempData = temps.slice(iStart, iEnd);
  const seasonGddData = gdds.slice(iStart, iEnd);
  const seasonGddAccumulations = calculateGddAccumulations(seasonGddData);

  let eggHatchIdx = -1;
  if (eggHatchDate) {
    const eggHatchDay = eggHatchDate.slice(5,10);
    eggHatchIdx = seasonGddData.findIndex(([date, _]) => date.slice(5,10) === eggHatchDay);
  } else {
    eggHatchIdx = seasonGddAccumulations.findIndex(gddAcc => gddAcc >= 767)
  }

  let halfEmergedDay = null;
  let newEggHatchDate = null;
  let baskervilleEminGdds = [];
  let dayOfSeason, daysIntoSeason;
  if (eggHatchIdx >= 0) {
    const tempsAfterEggHatch = seasonTempData.slice(eggHatchIdx);
    let sum = 0;
    baskervilleEminGdds = tempsAfterEggHatch.map(([date, mint, maxt]) => {
      let low = Math.min(Math.max(52, mint), 95);
      let high = Math.min(Math.max(52, maxt), 95);
      if (low > high || high < low) {
        throw new Error("Temps overlap");
      }
      sum += baskervilleEmin(low, high, 52);
      return [date, sum];
    });

    const halfEmergedDayArr = baskervilleEminGdds.find(([_,gddAcc]) => gddAcc >= halfEmergenceGdd);
    if (!halfEmergedDayArr) halfEmergedDay = null;
    halfEmergedDay = halfEmergedDayArr[0];
    ({ dayOfSeason, daysIntoSeason } = calcSeasonDates(halfEmergedDay, seasonTempData[0][0].slice(5)));
    newEggHatchDate = seasonGddData[eggHatchIdx][0]
  }

  return { year: seasonGddData.slice(-1)[0][0].slice(0,4), eggHatchDate: newEggHatchDate, halfEmergedDay, dayOfSeason, daysIntoSeason, baskervilleEminGdds };
}

export function calcCornRootWormChartData(gdds, temps, eggHatchDate, seasonBounds) {
  const newChartData = {
    seasonBounds,
    timeseries: {
      halfEmergedIdx: null,
      dates: [],
      current: [],
    },
    annual: {
      years: [],
      dateOccurred: [],
      dayOfSeason: [],
      daysIntoSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      // End of season
      const { year, halfEmergedDay, dayOfSeason, daysIntoSeason } = processSeason(temps, gdds, eggHatchDate, idxYearStart, i + 1);
      newChartData.annual.dateOccurred.push(halfEmergedDay);
      newChartData.annual.dayOfSeason.push(dayOfSeason);
      newChartData.annual.daysIntoSeason.push(daysIntoSeason);
      newChartData.annual.years.push(year);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      // Start of season
      idxYearStart = i;
    }
  }

  let idxYearEnd = null;
  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    // Finish partial season for yearly and to use as timeseries
    idxYearEnd = temps.length;
  } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
    // If using last completed season for timeseries only
    idxYearEnd = lastIdxProcessed + 1;
  }

  if (idxYearEnd !== null) {
    const { year, halfEmergedDay, dayOfSeason, daysIntoSeason } = processSeason(temps, gdds, eggHatchDate, idxYearStart, idxYearEnd);
    newChartData.annual.dateOccurred.push(halfEmergedDay);
    newChartData.annual.dayOfSeason.push(dayOfSeason === undefined ? null : dayOfSeason);
    newChartData.annual.daysIntoSeason.push(daysIntoSeason === undefined ? null : daysIntoSeason);
    newChartData.annual.years.push(year);
  }
  
  const { eggHatchDate: newEggHatchDate, baskervilleEminGdds } = processSeason(temps, gdds, eggHatchDate, selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? temps.length : selectedSeasonIndices[1] + 1);
  newChartData.eggHatchDate = newEggHatchDate;
  newChartData.timeseries = calcTimeseries(baskervilleEminGdds);    
  newChartData.timeseries.baskervilleEminGdds = baskervilleEminGdds;

  return newChartData;
}

export function cornRootWormEmergenceOptions(chartData, address) {
  return {
    chart: {
      marginRight: 20,
    },
    lang: {
      noData: 'Egg hatch has not been reached'
    },
    title: {
      text: 'Corn Root Worm Emergence',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.timeseries.current,
        name: 'Current Season',
        color: COLORS.darkRed,
      }
    ],
    xAxis: {
      categories: chartData.timeseries.dates.map(d => d.slice(5,10)),
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
      plotLines: [{
        color: 'rgb(150,150,150)',
        value: chartData.timeseries.halfEmergedIdx,
        label: {
          text: '50% Emergence',
          style: {
            color: 'rgb(80,80,80)',
          },
        },
        dashStyle: 'dash'
      }]
    },
    yAxis: [
      {
        min: 0,
        max: 103,
        endOnTick: false,
        tickInterval: 20,
        gridLineWidth: 0,
        title: {
          text: 'Percent Emergence',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.point) return '';

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={this.x} color={COLORS.darkRed} />

            <div className='mc-container'>
              <div className='mc-number'>{this.point.y}</div>
              <div className='mc-units'>%</div>
            </div>
          </div>
        );
      },
    },
  };
}

export function cornRootWormAnnualEmergenceOptions(chartData, address) {
  const min = Math.min(...(chartData.annual.dayOfSeason.filter(v => v !== null)));
  const max = Math.max(...chartData.annual.dayOfSeason);
  const avgDaysIntoSeason = Math.floor(chartData.annual.daysIntoSeason.reduce((a,b) => a + b, 0) / chartData.annual.daysIntoSeason.length);
  const avgDaysIntoSeasonTimestamp = getTime(addDays(parseISO('2021-01-01'), avgDaysIntoSeason));

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: 'Annual Date of 50% Emergence',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [{
      data: chartData.annual.dayOfSeason.map(v => ({
        y: v,
        marker: {
          fillColor: v > avgDaysIntoSeasonTimestamp ? COLORS.lightBlue : (v < avgDaysIntoSeasonTimestamp ? COLORS.lightRed : COLORS.lightGray)
        }
      })),
      name: 'Date Occurred',
      color: COLORS.lightGray,
      // color: COLORS.lightGray,
      zIndex: 1,
      // zones: [{
      //   value: avgDaysIntoSeasonTimestamp - 1,
      //   color: COLORS.lightRed
      // },{
      //   value: avgDaysIntoSeasonTimestamp + 1,
      //   color: COLORS.lightGray
      // },{
      //   color: COLORS.lightBlue
      // }]
    }],
    xAxis: {
      categories: chartData.annual.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        tickInterval: 30 * 24 * 3600 * 1000,
        gridLineWidth: 0,
        alignTicks: false,
        type: 'datetime',
        title: {
          text: 'Date Occurred',
          style: {
            color: COLORS.lightGray,
          },
        },
        labels: {
          format: '{value:%b}',
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            label: {
              text: `New Year's Day`,
              style: {
                color: 'rgb(150,150,150)',
              },
            },
            value: Date.parse('2021-01-01'),
          },{
            color: 'rgb(200,200,200)',
            label: {
              text: 'Avg. Date Occurred',
              style: {
                color: 'rgb(150,150,150)',
              },
            },
            value: avgDaysIntoSeasonTimestamp,
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const year = this.points[0].x;
        const yearIdx = this.points[0].point.x;
        const dateOccurred = chartData.annual.dateOccurred[yearIdx];
        const daysIntoSeason = chartData.annual.daysIntoSeason[yearIdx];

        const diffFromAvg =
          daysIntoSeason - avgDaysIntoSeason;
        let relativeToAverage = '';
        if (diffFromAvg > 0) {
          relativeToAverage = '+';
        }

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={year} color={COLORS.lightGray} />

            <div className='mc-occurred'>
              Occurred on {format(parseISO(dateOccurred), 'MMM do')}
            </div>

            <div className='mc-container'>
              <div className='mc-number'>{daysIntoSeason}</div>
              <div className='mc-units'>days into season</div>
            </div>

            <div className='mc-container'>
              <div className='mc-number'>
                {relativeToAverage}
                {diffFromAvg}
              </div>
              <div className='mc-units'>days from average</div>
            </div>
          </div>
        );
      },
    },
  };
}