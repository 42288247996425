import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../../components/tooltip-season/tooltip-season.component';
import TooltipThresholds from '../../../components/tooltip-thresholds/tooltip-thresholds.component';
import { COLORS } from '../../../utilities';

export function europeanCornBorerGenerationsOptions(chartData, address) {
  const timeseries = [...chartData.timeseries.current];
  const dates = chartData.timeseries.dates.map(d => d.slice(5,10));
  const min = 0;
  const max = Math.max(...timeseries);

  let generationLength = chartData.generation[0];
  let nextGenerationAt = generationLength;
  const generationIdxs = timeseries.reduce((acc, gdds, idx) => {
    if (gdds >= nextGenerationAt) {
      nextGenerationAt += generationLength;
      acc.push([idx, `Gen. ${acc.length + 1}`]);
    }
    
    return acc;
  }, []);
  
  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: 'European Corn Borer Generations',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        color: COLORS.darkRed,
      }
    ],
    xAxis: {
      categories: dates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
      plotLines: generationIdxs.map(([idx, text]) => ({
        color: 'rgb(150,150,150)',
        value: idx,
        label: {
          text,
          style: {
            color: 'rgb(80,80,80)',
          },
        },
        dashStyle: 'dash'
      }))
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Base 50 GDDs',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const gdds = point.y;
        const date = point.x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.darkRed} />

            <div className='mc-container'>
              <div className='mc-number'>{gdds}</div>
              <div className='mc-units'>Base 50 GDDs</div>
            </div>
          </div>
        );
      },
    },
  };
}

export function europeanCornBorerGenerationsAchievedOptions(chartData, address) {
  let min = Infinity;
  let max = 0;

  const firstNonOccurrenceIdx= chartData.thresholds.findIndex(tObj => tObj.dateOccurred.filter(v => v === null).length === tObj.dateOccurred.length);
  const validThresholds = chartData.thresholds.slice(0, firstNonOccurrenceIdx);

  const series = validThresholds
    .map((t, i) => {
      const tmin = Math.min(...t.dayOfSeason.filter((val) => val));
      const tmax = Math.max(...t.dayOfSeason);

      if (tmin < min) min = tmin;
      if (tmax > max) max = tmax;

      const data = t.dayOfSeason.map((val) => {
        const obj = {
          y: val,
          color: COLORS.thresholds[i],
        };

        if (val === tmin) {
          obj.color = 'blue';
        } else if (val === tmax) {
          obj.color = 'red';
        }

        return obj;
      });

      return {
        data,
        name: `Gen. ${i + 1}`,
        color: COLORS.thresholds[i],
      };
    })
    .reverse();

  return {
    chart: {
      marginRight: 20,
    },
    lang: {
      noData: 'No generations',
    },
    title: {
      text: 'Seasonal Date of Generations',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series,
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: {
      gridLineWidth: 0,
      alignTicks: false,
      tickInterval: 10 * 24 * 3600 * 1000,
      type: 'datetime',
      min,
      max,
      title: {
        text: 'Date Occurred',
        style: {
          color: 'rgb(0,150,0)',
        },
      },
      labels: {
        step: 4,
        format: '{value:%b}',
        style: {
          fontFamily:
            'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
        },
      },
      plotLines: [
        {
          color: 'rgb(200,200,200)',
          label: {
            text: `New Year's Day`,
            style: {
              color: 'rgb(150,150,150)',
            },
          },
          value: Date.parse('2021-01-01'),
        },
      ],
    },
    legend: {
      reversed: true
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const year = this.points[0].x;
        const yearIdx = this.points[0].point.x;

        return renderToStaticMarkup(
          <div>
            <TooltipSeason year={year} color={COLORS.thresholds[1]} />
            <TooltipThresholds
              thresholds={validThresholds}
              yearIdx={yearIdx}
            />
          </div>
        );
      },
    },
  };
}