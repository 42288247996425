import { regionNames } from "../../utilities";

import { chillUnitsSharedStateDefinitions } from "./chill/info";
import { gddsSharedStateDefinitions } from "./gdds/info";
import { rainfallExtremesSharedStateDefinitions } from "./rainfall-extremes/info";
import { extremeColdSharedStateDefinitions } from "./extreme-cold/info";
import { extremeHeatSharedStateDefinitions } from "./extreme-heat/info";
import { climatologicalSharedStateDefinitions } from "./climatological/info";

const sharedStateDefinitions = {
  'regionSelector': {
    options: regionNames,
    initValue: regionNames,
    props: {
      listTypeSingular: 'Region',
      listTypePlural: 'Regions',
    }
  },
  'departureSelector': {
    options: ['Last Year', 'Normal'],
    initValue: 'Last Year'
  },
  ...chillUnitsSharedStateDefinitions,
  ...gddsSharedStateDefinitions,
  ...rainfallExtremesSharedStateDefinitions,
  ...extremeColdSharedStateDefinitions,
  ...extremeHeatSharedStateDefinitions,
  ...climatologicalSharedStateDefinitions
};

export default sharedStateDefinitions;