import { format, parseISO, isBefore } from "date-fns";

import { TODAY, updateOverlayFromR2, isInSeason, isPastThreshold, calcSeasonYear } from "../../../utilities";

export const TAB_NAME = 'Extreme Cold';
export const DATA_NAME = 'mintMaxt';

export const calcChartData = (temps, dataIdx, thresholds, seasonBounds, direction, TODAY) => {
  const compFn = isPastThreshold(thresholds, direction);
  const newChartData = {
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;

    if (isToday && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.toDate.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.years.push(parseInt(strDate.slice(0,4)));
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = temps.slice(idxYearStart);
    const seasonYear = calcSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1]);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    newChartData.yearlySeasons.fullSeason.push(null);
  }

  const selectedSeasonData = temps.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? temps.length : selectedSeasonIndices[1] + 1);
  newChartData.timeseries = selectedSeasonData.reduce((acc, dayData) => {
    acc.dates.push(dayData[0]);
    acc.current.push(dayData[dataIdx]);
    return acc;
  }, { dates: [], current: [] });

  return newChartData;
};

export function updateChartData({ selectedTab, selectedSubTab, selectedLocation, tabsSharedState, rawChartData }, _, { setChartData }) {
  if (
    selectedTab === TAB_NAME &&
    selectedLocation in rawChartData && DATA_NAME in rawChartData[selectedLocation]
  ) {
    let thresholds = tabsSharedState.extremeColdThresholdSelector.value;
    if (typeof thresholds === 'function') thresholds = thresholds({selectedSubTab})[0];
    let seasonBounds = tabsSharedState.extremeColdSeasonBoundsSelector.value;
    if (typeof seasonBounds === 'function') seasonBounds = seasonBounds();

    const dataIdx = selectedSubTab.includes('Min') ? 1 : 2;

    const newChartData = calcChartData(rawChartData[selectedLocation][DATA_NAME], dataIdx, thresholds, seasonBounds, 'below', TODAY);
    return () => setChartData(newChartData);
  }
  return () => {};
}

export function updateOverlay({ selectedSubTab }, _, { updateOverlayData }) {
  const jsonFileName = `days_under_32_${selectedSubTab.split(' ')[0].toLowerCase()}.json`;
  const jsonFileDataKey = 'departures';
  return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
    .then(d => () => updateOverlayData(d));
}

export function updateTitle({selectedSubTab, display, overlayDataDate, chartData}) {
  try {
    let sdate, edate;
    if (display === 'map') {
      const dateObj = parseISO(overlayDataDate);
      const isCurrentSeasonYear = isBefore(dateObj, new Date(dateObj.getFullYear(), 6, 1));
      const syear = dateObj.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
      sdate = format(new Date(syear, 6, 1), 'LLL do, yyyy');
      edate = format(dateObj, 'LLL do, yyyy');
    } else {
      sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
      edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
    }

    return {
      type: `Days With ${selectedSubTab.slice(0,3)} Under ${selectedSubTab.slice(6,8)}`,
      sdate,
      edate
    };
  } catch {
    return null;
  }
}