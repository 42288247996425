export function averageArray(arr) {
  return arr.reduce((acc, v, i) => {
    acc += v;
    if (i === arr.length - 1) {
      return Math.round(acc / arr.length * 100) / 100;
    } else {
      return acc;
    }
  }, 0)
}

export function countAbove(arr, threshold) {
  return arr.reduce((acc, v) => {
    if (v >= threshold) {
      acc += 1;
    }
    return acc;
  }, 0);
}

export const isPastThreshold = (threshold, direction) => {
  if (direction === 'above') {
    return (v) => v > threshold;
  } else if (direction === 'below') {
    return (v) => v < threshold;
  } else if (direction === 'range') {
    return (v) => threshold[0] <= v && v <= threshold[1];
  } else {
    return (v) => v === threshold;
  }
};